import { useEffect, useState } from "react";
import { addYears, addMonths } from 'date-fns';
import 'css/ResManagement.css';
import Excel from "components/parts/Excel";
import moment from "moment";
import DashSelect from "./DashSelect";
import DashRefuel from "./DashRefuel";
import { useDispatch, useSelector } from "react-redux";
import { reservationsPutAxios } from "base/redux/reservations/reservationsPutAxios";
import { resData } from "base/redux/reservations/reservationsSlice";
import { usersData } from "base/redux/accounts/usersSlice";
import { usersGetAxios } from "base/redux/accounts/usersGetAxios";
import useFieldValues from "base/hook/useFieldValues";
import axios from "axios";


const INIT_FILED_VALUES = {
    part_name: '',
    user_name: '',
}


function ResManagement() {
    const startYear = addYears(new Date(), -1);
    const endMonth = addMonths(new Date(), 1);
    const dispatch = useDispatch();
    const resInfo = useSelector(resData);
    const userInfo = useSelector(usersData);
    const [optionCarId, setOptionCarId] = useState();
    const [selectSearch, setSelectSearch] = useState();
    const [searchInfo, setSearchInfo] = useState();


    const optionClick = (e) => {
        setOptionCarId(parseInt(e.target.value));
    }

    const { 
        fieldValues, 
        handleFieldChange, 
        emptyFieldValues,
    } = useFieldValues(INIT_FILED_VALUES);

    useEffect(() => {
        dispatch(reservationsPutAxios({ startdate: startYear, enddate: endMonth }))
        dispatch(usersGetAxios());
    }, []);

    const handleSearchSelect = (e) => {
        setSelectSearch(e.target.value);
        emptyFieldValues();
    }

    const handleSearchButton = async (e) => {
        e.preventDefault();
            await axios({
                url: `/api/reservations/search?${selectSearch === 'part_name' ? 'part_name=' + fieldValues.part_name : 'user_name=' + fieldValues.user_name}`,
                method: 'put',
                data: { ...fieldValues, part_name: fieldValues.part_name, user_name: fieldValues.user_name }
            })
            .then(function(response) {
                setSearchInfo(response?.data);
            })
            .catch(function(error) {
                console.log(error);
            })
        };

    const handleEnterSearch = (e) => {
        if (e.key === 'Enter') {
            handleSearchButton(e);
        }
    };

    return (
        <div className="resBaseBg w-screen h-screen">
        <DashSelect />
        <DashRefuel />
        <div className="resManageBg">
            <div className="resManageBB">
                <span className='resManageSpan1'>+</span>
                <span className="resManageSpan2">대여내역</span>
            </div>

            <Excel optionCarId={optionCarId} />

            <div className="userManagementSearchSet flex">
                    <form onKeyPress={handleEnterSearch}>
                        <select name="search" id="search" defaultValue="user_name" onChange={handleSearchSelect} className="userManagementSearchSelect">
                            <option value="part_name" className="userManagementSearchSelectOption">소속</option>
                            <option value="user_name" className="userManagementSearchSelectOption">회원명</option>
                        </select>
                        <input 
                            type='text'
                            name={selectSearch === 'part_name' ? 'part_name' : 'user_name'}
                            value={selectSearch === 'part_name' ? fieldValues.part_name : fieldValues.user_name} 
                            onChange={handleFieldChange} 
                            className="userManagementSearchInput" 
                        />
                    </form>
                </div>

            <div className="ResManageList">
                <table className="ResManageTable">
                    <thead className="ResManageThead">
                        <tr>
                            <th scope="col" className="px-4 py-3 relative w-20 left-9">
                                <select name="carId" id="carId" className='ResListSelect outline-none' onChange={optionClick}>
                                    <option value="">차량</option>
                                    <option value="2">모닝</option>
                                    <option value="1">투싼</option>
                                </select>
                            </th>
                            <th scope="col" className="px-6 py-3 relative w-24 left-[60px]">
                                대여일
                            </th>
                            <th scope="col" className="px-3 py-3 relative w-24 left-[85px]">
                                반납일
                            </th>
                            <th scope="col" className="px-6 py-3 relative w-24 left-[120px]">
                                사용자
                            </th>
                            <th scope="col" className="px-6 py-3 relative w-24 left-32">
                                목적지
                            </th>
                            <th scope="col" className="px-3 py-3 relative w-26 left-36">
                                주행거리
                            </th>
                            <th scope="col" className="px-6 py-3 relative w-26 left-32">
                                누적주행거리
                            </th>
                            <th scope="col" colSpan={4} className="px-1 py-3">
                                <span className="relative left-8">차량점검내용</span>
                                <th scope="col" className="px-6 py-3 relative w-[150px] left-[95px]">
                                    차량외관 파손여부(휠/타이어/차량본체)
                                </th>
                                <th scope="col" className="px-6 py-3 relative w-[127px] left-12">
                                    조명장치(전조/후미/비상등)
                                </th>
                                <th scope="col" className="px-4 py-3 relative w-[120px] left-4">
                                    계기판(경고등 점등여부)
                                </th>
                                <th scope="col" className="px-6 py-3 relative w-[125px] right-5">
                                    기타(조향/제동계통)
                                </th>
                            </th>
                            <th scope="col" className="px-2 py-3 relative w-20 right-10">
                                주유
                            </th>
                            <th scope="col" className="px-4 py-3 relative w-20 right-2">
                                주차
                            </th>
                        </tr>
                    </thead>
                
                    { !searchInfo ? 
                        resInfo
                            ?.filter(({ carId }) => parseInt(optionCarId) ? carId === parseInt(optionCarId) : true)
                            ?.filter(({ rentalState }) => rentalState === 1)
                            ?.map(({ reservationId, startdate, enddate, userId, carId, destination, distance, mileage, damage, lighting, dashboard, besides, refueling_amount, parking}) => 
                            <tbody key={reservationId}>
                                <tr className="ResManageTbody">
                                    <th scope="row" className="ResManageTbodyTh">
                                        {carId === 1 ? '투싼' : '모닝'}
                                    </th>
                                    <td className="px-6 py-4 w-[140px]">
                                        <span className="relative right-6">{moment(startdate).format('YYYY-MM-DD HH:mm')}</span>
                                    </td>
                                    <td className="px-3 py-4 w-[120px]">
                                        <span className="relative right-8">{moment(enddate).format('YYYY-MM-DD HH:mm')}</span>
                                    </td>
                                    <td className="px-6 py-4">
                                        <span className="relative right-6">{userInfo?.filter(({ user_id }) => user_id === userId)?.map(({ username }) => username)}</span>
                                    </td>
                                    <td className="px-6 py-4">
                                        <span className="relative right-[65px]">{destination?.toLocaleString()}</span>
                                    </td>
                                    <td className="px-3 py-4 w-[60px]">
                                        <span className="relative right-[70px]">{distance?.toLocaleString()}</span>
                                    </td>
                                    <td className="px-4 py-4">
                                        <span className="relative right-12">{mileage}</span>
                                    </td>
                                    <td className="px-4 py-4">
                                        <span className="relative right-5">{damage}</span>
                                    </td>
                                    <td className="px-4 py-4">
                                        <span className="relative right-3">{lighting}</span>
                                    </td>
                                    <td className="px-4 py-4">
                                        <span className="relative right-4">{dashboard}</span>
                                    </td>
                                    <td className="px-4 py-4">
                                        <span className="relative right-4">{besides}</span>
                                    </td>
                                    <td className="px-0 py-4">
                                        {refueling_amount?.toLocaleString()}
                                    </td>
                                    <td className="px-6 py-4">
                                        {parking}
                                    </td>
                                </tr>
                            </tbody>
                        ) :
                        searchInfo
                            ?.filter(({ carId }) => parseInt(optionCarId) ? carId === parseInt(optionCarId) : true)
                            ?.filter(({ rentalState }) => rentalState === 1)
                            ?.map(({ reservationId, startdate, enddate, userId, carId, destination, distance, mileage, damage, lighting, dashboard, besides, refueling_amount, parking}) => 
                            <tbody key={reservationId}>
                                <tr className="ResManageTbody">
                                    <th scope="row" className="ResManageTbodyTh">
                                        {carId === 1 ? '투싼' : '모닝'}
                                    </th>
                                    <td className="px-6 py-4 w-[140px]">
                                        <span className="relative right-6">{moment(startdate).format('YYYY-MM-DD HH:mm')}</span>
                                    </td>
                                    <td className="px-3 py-4 w-[120px]">
                                        <span className="relative right-8">{moment(enddate).format('YYYY-MM-DD HH:mm')}</span>
                                    </td>
                                    <td className="px-6 py-4">
                                        <span className="relative right-6">{userInfo?.filter(({ user_id }) => user_id === userId)?.map(({ username }) => username)}</span>
                                    </td>
                                    <td className="px-6 py-4">
                                        <span className="relative right-[65px]">{destination?.toLocaleString()}</span>
                                    </td>
                                    <td className="px-3 py-4 w-[60px]">
                                        <span className="relative right-[70px]">{distance?.toLocaleString()}</span>
                                    </td>
                                    <td className="px-4 py-4">
                                        <span className="relative right-12">{mileage}</span>
                                    </td>
                                    <td className="px-4 py-4">
                                        <span className="relative right-5">{damage}</span>
                                    </td>
                                    <td className="px-4 py-4">
                                        <span className="relative right-3">{lighting}</span>
                                    </td>
                                    <td className="px-4 py-4">
                                        <span className="relative right-4">{dashboard}</span>
                                    </td>
                                    <td className="px-4 py-4">
                                        <span className="relative right-4">{besides}</span>
                                    </td>
                                    <td className="px-0 py-4">
                                        {refueling_amount?.toLocaleString()}
                                    </td>
                                    <td className="px-6 py-4">
                                        {parking}
                                    </td>
                                </tr>
                            </tbody>
                        )}
                </table>
            </div>
        </div>
    </div>
    )
}

export default ResManagement;