import { useAuth } from 'base/hook/Authcontext';
import TopNav from 'components/parts/TopNav';
import LoginPage from 'pages/LoginPage';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import AdminRouter from 'pages/router/AdminRouter';
import ErrorForbidden from 'components/parts/ErrorForbidden';
import NotFound from 'components/parts/NotFound';
import UserRouter from 'pages/router/UserRouter';


function App() {
  const queryClient = new QueryClient();
  const [auth] = useAuth();

  return(
    <div className='wrapper'>
      <div className='contentWrapper'>
        {auth?.isLoggedIn && 
          <TopNav />
        }

        <QueryClientProvider client={queryClient}>
          <Routes>
            {!auth?.isLoggedIn && ( 
              <Route path='/' element={<LoginPage />} />
            )}
            {auth?.authority === 0 ? (
                <Route path='/' element={<Navigate to='/user/' />} />
            ) : (
              <Route path='/' element={<Navigate to='/admin/' />} />
            )}

              <Route path='/admin/*' element={auth?.authority === 1 ? <AdminRouter /> : <ErrorForbidden />} />,
              
              <Route path='/user/*' element={auth?.authority === 0 ? <UserRouter /> : <ErrorForbidden />} />
          
              <Route path="*" element={<NotFound />} />
          </Routes>
        </QueryClientProvider>
      </div>
    </div>
  )
}

export default App;
