import axios from "axios"
import { addYears, addMonths } from "date-fns";
import { reservationsPutAxios } from "./reservationsPutAxios";


const startYears = addYears(new Date(), -1);
const endMonth = addMonths(new Date(), 1);

export function reservationsDeleteAxios(resData) {
        return async function reservationsDeleteThunk(dispatch, getState) {
            await axios.delete(`/api/reservations/${resData?.reservationId}`, resData)
            dispatch(reservationsPutAxios({ startdate: startYears, enddate: endMonth,}))
        }
    }