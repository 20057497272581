import useFieldValues from "base/hook/useFieldValues";
import DialogBox from 'react-modeless'
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ReturnModalCar from 'static/image/ResModalCar771.png';
import ReturnDate from 'static/image/Return650.png';
import 'css/ResReturnButton.css';
import { useEffect } from "react";
import { checkPostAxios } from "base/redux/check/checkPostAxios";
import { checkData } from "base/redux/check/checkSlice";
import 'css/CarInspectionModal.css';


const INIT_FILED_VALUES = {
  carId: '',
  checkDate: moment(new Date()).format('YYYY-MM-DD'),
  checkMileage: '',
  checkContent: '',
}


function CarCheckAddTButton ({ addModalTIsOpen, setAddModalTIsOpen}) {
    const dispatch = useDispatch();
    const checkInfo = useSelector(checkData);

    const { 
        fieldValues, 
        handleFieldChange, 
        emptyFieldValues,
    } = useFieldValues(INIT_FILED_VALUES);
    
    const onFetchClicked = (e) => {
        e.preventDefault();

        dispatch(checkPostAxios({ ...fieldValues, carId: 1, checkMileage: parseInt(fieldValues.checkMileage), checkContent: fieldValues.checkContent }))
    }

    useEffect(() => {
        emptyFieldValues(); 
        setAddModalTIsOpen(false);
    }, [checkInfo])

    return(
        <>
            <DialogBox
                ariaHideApp={false}
                isOpen={addModalTIsOpen}
                onRequestClose={() => setAddModalTIsOpen(false)}
                shouldCloseOnOverlayClick={false}
                clickBackdropToClose={false}
                style={{
                    backgroundColor: 'rgba(58, 61, 63, 0.8)',
                    width: '100%',
                    height: '100vh',
                }}  
            >

            <form onSubmit={onFetchClicked} className="checkModal">
                <div className="checkModalBg">
                    <div className="absolute top-[60px] right-[655px]">
                        <button className="checkXButton" onClick={() => [emptyFieldValues(), setAddModalTIsOpen(false)]}>
                            <span className="ReturnXButtonSpan">X</span>
                        </button>
                    </div>
                    <div className="ReturnModalBB">
                        <span className='ReturnModalSpan1'>+</span>
                        <span className="ReturnModalSpan2">점검등록</span>
                    </div>

                    <div className="flex">
                        <img src={ReturnDate} alt="◽" className="ReturnDateImage" />
                        <label htmlFor="" className="ReturnDateLabel">등록일</label>
                        <input type="text" placeholder={moment(new Date()).format('YYYY-MM-DD')} disabled={true} className="ReturnDateInput" />
                    </div>

                    <div>
                        <div className="ReturnMileageBB flex">
                            <label htmlFor="checkMileage" className="ReturnMileageLabel">점검주행거리</label>
                            <input type="checkMileage" name="checkMileage" id="checkMileage" placeholder="계기판 숫자를 적어주세요" required={true} className="ReturnMileageInput" value={fieldValues.checkMileage} onChange={handleFieldChange} pattern="^[0-9]+$" title="계기판의 주행거리를 숫자로만 작성해주세요" />
                        </div>
                    </div>

                    <div className="checkDamageSet">
                        <div className="ReturnDamageBB flex">
                            <label htmlFor="checkContent" className="ReturnDamageLabel">점검상세내용</label>
                        </div>
                        <textarea type="checkContent" name="checkContent" id="checkContent" placeholder="점검에 대한 상세 내용을 적어주세요" required={true} className="checkDamageText" value={fieldValues.checkContent} onChange={handleFieldChange} />
                    </div>

                    <div className="checkButtonSet flex justify-end">
                        <button className="ReturnCancelButton" type="button" onClick={() => [emptyFieldValues(), setAddModalTIsOpen(false)]}>
                            <span className="ReturnCancelSpan">취소</span>
                        </button>
                        <button className="ReturnButton" type="submit">
                            <span className="ReturnSpan">등록</span>
                        </button>
                    </div>

                    <img src={ReturnModalCar} alt="🚘" className="ReturnModalCarImage" />
                </div>
            </form>
        </DialogBox>
        </>
    )
}

function CarCheckAddMButton ({ addModalMIsOpen, setAddModalMIsOpen}) {
  const dispatch = useDispatch();
  const checkInfo = useSelector(checkData);

  const { 
      fieldValues, 
      handleFieldChange, 
      emptyFieldValues,
  } = useFieldValues(INIT_FILED_VALUES);
  
  const onFetchClicked = (e) => {
      e.preventDefault();

      dispatch(checkPostAxios({ ...fieldValues, carId: 2, checkMileage: parseInt(fieldValues.checkMileage), checkContent: fieldValues.checkContent }))
  }

  useEffect(() => {
      emptyFieldValues(); 
      setAddModalMIsOpen(false);
  }, [checkInfo])

  return(
      <>
          <DialogBox
              ariaHideApp={false}
              isOpen={addModalMIsOpen}
              onRequestClose={() => setAddModalMIsOpen(false)}
              shouldCloseOnOverlayClick={false}
              clickBackdropToClose={false}
              style={{
                  backgroundColor: 'rgba(58, 61, 63, 0.8)',
                  width: '100%',
                  height: '100vh',
              }}  
          >

          <form onSubmit={onFetchClicked} className="checkModal">
              <div className="checkModalBg">
                  <div className="absolute top-[60px] right-[655px]">
                      <button className="checkXButton" onClick={() => [emptyFieldValues(), setAddModalMIsOpen(false)]}>
                          <span className="ReturnXButtonSpan">X</span>
                      </button>
                  </div>
                  <div className="ReturnModalBB">
                      <span className='ReturnModalSpan1'>+</span>
                      <span className="ReturnModalSpan2">점검등록</span>
                  </div>

                  <div className="flex">
                      <img src={ReturnDate} alt="◽" className="ReturnDateImage" />
                      <label htmlFor="" className="ReturnDateLabel">등록일</label>
                      <input type="text" placeholder={moment(new Date()).format('YYYY-MM-DD')} disabled={true} className="ReturnDateInput" />
                  </div>

                  <div>
                      <div className="ReturnMileageBB flex">
                          <label htmlFor="checkMileage" className="ReturnMileageLabel">점검주행거리</label>
                          <input type="checkMileage" name="checkMileage" id="checkMileage" placeholder="계기판 숫자를 적어주세요" required={true} className="ReturnMileageInput" value={fieldValues.checkMileage} onChange={handleFieldChange} pattern="^[0-9]+$" title="계기판의 주행거리를 숫자로만 작성해주세요" />
                      </div>
                  </div>

                  <div className="checkDamageSet">
                      <div className="ReturnDamageBB flex">
                          <label htmlFor="checkContent" className="ReturnDamageLabel">점검상세내용</label>
                      </div>
                      <textarea type="checkContent" name="checkContent" id="checkContent" placeholder="점검에 대한 상세 내용을 적어주세요" required={true} className="checkDamageText" value={fieldValues.checkContent} onChange={handleFieldChange} />
                  </div>

                  <div className="checkButtonSet flex justify-end">
                      <button className="ReturnCancelButton" type="button" onClick={() => [emptyFieldValues(), setAddModalMIsOpen(false)]}>
                          <span className="ReturnCancelSpan">취소</span>
                      </button>
                      <button className="ReturnButton" type="submit">
                          <span className="ReturnSpan">등록</span>
                      </button>
                  </div>

                  <img src={ReturnModalCar} alt="🚘" className="ReturnModalCarImage" />
              </div>
          </form>
      </DialogBox>
      </>
  )
}

export { CarCheckAddTButton, CarCheckAddMButton } ;