import axios from 'axios';
import { reservationsPutAxios } from 'base/redux/reservations/reservationsPutAxios';
import { resData } from 'base/redux/reservations/reservationsSlice';
import 'css/DashBoard.css'
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';


function DashRefuel() {
  const years = new Date().getFullYear();
  const resInfo = useSelector(resData);
  const dispatch = useDispatch();
  const [refuelingCountData, setRefuelingCountData] = useState();
  const refuelingTCount = refuelingCountData?.filter(({ car_id }) => car_id === 1)?.map(({ count }) => count);
  const refuelingMCount = refuelingCountData?.filter(({ car_id }) => car_id === 2)?.map(({ count }) => count);
  const refuelingNCount = resInfo?.filter(({rentalState, refueling_amount}) => rentalState === 1 && refueling_amount === 0)?.map(({refueling_amount}) => refueling_amount);
  
  useEffect(() => {
    dispatch(reservationsPutAxios({ startdate: years + '-01-01', enddate: years + '-12-31' }))
  }, []);

  const initialOptions = {
    title : { 
      text : "" 
    },
    chart : { 
      type : "pie",
      style: {
        color: '#707070',
        fontFamily: 'S-CoreDream-5',
        fontWeight: 'normal',
      },
      backgroundColor:'rgba(255, 255, 255, 0)'
    },
    legend: {
      layout: 'vertical',
      floating: false,
      align: 'center',
      verticalAlign: 'bottom',
      symbolRadius:20,//범례 심볼 radius 지정
        symbolWidth:15,
        symbolHeight:10,
        itemDistance:150,//범례 간 간격 지정.
        itemStyle: {
          color:'#8e8e8f',
          fontSize: '12px',
          fontWeight:'normal'
        },
      x: 10,//가로 위치 지정.
      y: -3,//세로 위치 지정.
    },
    credits: {
      enabled: false
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:f} 번</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
          distance: -20,
        },
      },
    },
    series: [{
      type: 'pie',
      name:'',
      innerSize: '80%',//도넛 차트 지정시 내부 구멍 너비를 설정.(도넛 차트 필수 지정 옵션)
      data:[
        {
          name: 'MORNING',
          y: parseInt(refuelingMCount),
          color:'#fea031',
        },
        {
          name: 'TUCSON',
          y: parseInt(refuelingTCount),
          color:'#69a6f7',
        },
        {
          name: 'No Refueling',
          y: refuelingNCount.length,
          color:'rgba(105, 166, 247, 0.3)',
        }],
      },
    ],
  }; 
  
  useEffect(() => {
    axios({
        url: `/api/graph/CountRefuelingCheck`,
        method: 'put',
        data: {
          startdate: years + '-01-01',
          enddate: years + '-12-31',
        }    
    })
    .then(function(response) {
      setRefuelingCountData(response.data);
    })
    .catch(function(error) {
        console.log(error);
    })
  }, []);

  return (
    <div className="dashRefuelBg select-none">
      <div className="dashRefuelGB">
        <span className="dashRefuelSpan">NUMBER OF REFUELS</span>
      </div>

      <div className='dashRefuelCarSet flex justify-end'>
        <div className='dashRefuelMorning'>
          <span className='dashRefuelMorningSpan'>MORNING</span>
        </div>
        <h1 className='dashRefuelMorningH1'>{refuelingMCount}</h1>
        <div className='dashRefuelTucson'>
          <span className='dashRefuelTucsonSpan'>TUCSON</span>
        </div>
        <h1 className='dashRefuelTucsonH1'>{refuelingTCount}</h1>
      </div>

      <div className='absolute'>
        <HighchartsReact 
          highcharts={Highcharts}
          options={initialOptions}
          containerProps={{ className: "dashRefuelCircleChart" }}
        />
      </div>
    </div>
  )
}

export default DashRefuel;