import DashCalendar from './DashCalendar';
import DashCar from './DashCar';
import DashNumber from './DashNumber';
import DashRefuel from './DashRefuel';
import DashSelect from './DashSelect';
import DashPlan from './DashPlan';
import 'css/DashBoard.css'


function DashBoard() {  
    return (
        <div className="dashBg w-screen h-screen">
            <DashSelect />
            <DashRefuel />
            <DashCar />
            <DashCalendar />
            <DashNumber />
            <DashPlan />
        </div>
    );
};

export default DashBoard;