import { useEffect, useState } from "react";
import 'css/CarManagement.css';
import DashSelect from "./DashSelect";
import DashRefuel from "./DashRefuel";
import lightCar from 'static/image/LightCar809.png';
import darkCar from 'static/image/DarkCar810.png';
import userAdd from 'static/image/UserAdd580.png';
import { useDispatch, useSelector } from "react-redux";
import { carsGetAxios } from "base/redux/cars/carsGetAxios";
import { carsData } from "base/redux/cars/carsSlice";
import { carsPutAxios } from "base/redux/cars/carsPutAxios";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, } from 'react-accessible-accordion';
import Search from 'static/image/Search608.png';
import { CarInspectionMModal, CarInspectionTModal } from "./CarInspectionModal";
import { CarCheckAddMButton, CarCheckAddTButton } from "./CarCheckAddButton";
import { emergencyData } from "base/redux/emergency/emergencySlice";
import { emergencyPutAxios } from "base/redux/emergency/emergencyPutAxios";
import { CarEmergencyAddMButton, CarEmergencyAddTButton } from "./CarEmergencyAddModal";


function CarManagement() {
    const years = new Date().getFullYear();
    const dispatch = useDispatch();
    const carsInfo = useSelector(carsData);
    const emergencyInfo = useSelector(emergencyData);
    const tucsonInfo = carsInfo?.find(({carId}) => carId === 1);
    const morningInfo = carsInfo?.find(({carId}) => carId === 2);
    const tucsonEmergency = emergencyInfo?.filter(({carId}) => carId === 1);
    const morningEmergency = emergencyInfo?.filter(({carId}) => carId === 2);
    const carTStatus = carsInfo?.filter(({ carId }) => carId === 1)?.map(({ useStatus }) => useStatus);
    const carMStatus = carsInfo?.filter(({ carId }) => carId === 2)?.map(({ useStatus }) => useStatus);
    const [inspectionModalTIsOpen, setInspectionModalTIsOpen] = useState(false);
    const [inspectionModalMIsOpen, setInspectionModalMIsOpen] = useState(false);
    const [addModalTIsOpen, setAddModalTIsOpen] = useState(false);
    const [addModalMIsOpen, setAddModalMIsOpen] = useState(false);
    const [emergencyModalTIsOpen, setEmergencyModalTIsOpen] = useState(false);
    const [emergencyModalMIsOpen, setEmergencyModalMIsOpen] = useState(false);

    console.log(tucsonEmergency?.[1])

    const handleOpenTModal = () => {
        setInspectionModalTIsOpen(true);
    }

    const handleOpenMModal = () => {
        setInspectionModalMIsOpen(true);
    }

    const handleOpenAddT = () => {
        setAddModalTIsOpen(true);
    }

    const handleOpenAddM = () => {
        setAddModalMIsOpen(true);
    }

    const handleOpenET = () => {
        setEmergencyModalTIsOpen(true);
    }
    
    const handleOpenEM = () => {
        setEmergencyModalMIsOpen(true);
    }

    useEffect(() => {
        dispatch(carsGetAxios());
        dispatch(emergencyPutAxios({ startdate: years + '-01-01', enddate: years + '-12-31' }))
    }, []);

    const handleChangeTStatus = () => {
        dispatch(carsPutAxios({ carId: 1, use_status: carTStatus?.[0] === true ? 0 : 1 }));
    }

    const handleChangeMStatus = () => {
        dispatch(carsPutAxios({ carId: 2, use_status: carMStatus?.[0] === true ? 0 : 1 }));
    }



    console.log(emergencyInfo)

    return (
        <div>
            <div className="carListBg w-screen h-screen">
                <DashSelect />
                <DashRefuel />
                <div className="carBaseBg">
                    <div className="carBaseBB">
                        <span className='carBaseSpan1'>+</span>
                        <span className="carBaseSpan2">차량관리</span>
                    </div>

                    <div className="carBaseSet flex justify-between">
                        <div className="carTBaseList">
                            <table className="carTBaseTable">
                                <thead className="carTBaseThead">
                                    <tr>
                                        <th scope="col" className="px-4 py-3 relative bottom-4">
                                            CAR.01
                                            <span className="carBaseSpan">ASSEMBBLE CAR MANAGEMENT SYSTEM</span>
                                        </th>
                                    </tr>
                                </thead>
                            
                                    <tbody>
                                        <tr className="carTBaseTbody">
                                            <tr>
                                                <th scope="row" className="carTBaseTbodyTh">
                                                    <img src={lightCar} alt="" className="carTLightImg" />
                                                    차량종류

                                                </th>
                                                <td className="px-6 py-4 w-[140px]">
                                                        <span className="relative left-32">{tucsonInfo?.carName}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className="carTBaseTbodyTh">
                                                    <img src={darkCar} alt="" className="carTDarkImg" />
                                                    차량정보

                                                </th>
                                                <td className="px-6 py-4 w-[140px]">
                                                        <span className="relative left-32">{tucsonInfo?.carNum}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className="carTBaseTbodyTh">
                                                <img src={lightCar} alt="" className="carTLightImg" />
                                                    취득일

                                                </th>
                                                <td className="px-6 py-4 w-[140px]">
                                                        <span className="relative left-32">{tucsonInfo?.carYear}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className="carTBaseTbodyTh">
                                                <img src={darkCar} alt="" className="carTDarkImg" />

                                                    연식

                                                </th>
                                                <td className="px-6 py-4 w-[140px]">
                                                        <span className="relative left-32">{tucsonInfo?.carYear.slice(0, 4)}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className="carTBaseTbodyTh">
                                                <img src={lightCar} alt="" className="carTLightImg" />

                                                    관리책임자

                                                </th>
                                                <td className="px-6 py-4 w-[140px]">
                                                        <span className="relative left-32">{tucsonInfo?.admin}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className="carTBaseTbodyTh">
                                                <img src={darkCar} alt="" className="carTDarkImg" />

                                                    사용상태

                                                </th>
                                                <td className="px-6 py-4 w-[140px]">
                                                    { tucsonInfo?.useStatus === true 
                                                        ? <span className="relative left-32">사용중</span>
                                                        : <span className="relative left-32 text-red-600">사용중지</span>
                                                    }
                                                        <button className="carTBaseTbodyTdButton outline-none" onClick={handleChangeTStatus}>
                                                            <span className="carTBaseTbodyTd">▶</span>
                                                        </button>
                                                </td>
                                            </tr>
                                            <Accordion allowZeroExpanded className="carMItemBg absolute">
                                                    <AccordionItem>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton className="carMItemButton">
                                                                <tr>
                                                                    <th scope="row" className="carMBaseTbodyTh">
                                                                    <img src={lightCar} alt="" className="carMLightImg" />

                                                                        최근정비내역

                                                                    </th>

                                                                    <td className="px-0 py-4 w-[140px]">
                                                                        <span className="relative left-32">{'부품 및 소모품 교환주기'}</span>
                                                                        <button className="carMItemDownButton outline-none">
                                                                            <span className="carMItemDownButtonSpan">▽</span>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <button className="carManageDetailsButton select-none" onClick={handleOpenTModal}>
                                                                <img src={Search} alt="+" className="carManageDetailsImage" />
                                                            </button>
                                                            <button className="carManageAddButton select-none ml-2" onClick={handleOpenAddT}>
                                                                <img src={userAdd} alt="+" className="carManageAddImage" />
                                                            </button>
                                                            <div className="carMBaseBorder" />
                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        엔진오일

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'10,000 Km'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'1년 2회'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorderTr" />

                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        오일필터

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'10,000 Km'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'1년 2회'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorderTr" />

                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        에어클리너

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'40,000 Km'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'-'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorderTr" />

                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        미션오일

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'20,000 Km'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'-'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorderTr" />

                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        브레이크오일

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'30,000 Km'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'매 2년'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorderTr" />

                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        파워스티어링오일

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'30,000 Km'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'매 2년'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorder" />
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                    
                                                    <AccordionItem>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton className="carMItemButton">
                                                                <tr>
                                                                    <th scope="row" className="carMBaseTbodyTh">
                                                                    <img src={lightCar} alt="" className="carMLightImg" />

                                                                        긴급출동서비스

                                                                    </th>

                                                                    <td className="px-0 py-4 w-[140px]">
                                                                        <span className="relative left-32">{'사유 및 서비스 사용일자'}</span>
                                                                        <button className="carMItemDownButton outline-none">
                                                                            <span className="carMItemDownButtonSpan">▽</span>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <button className="carManageAddButton2 select-none" onClick={handleOpenET}>
                                                                <img src={userAdd} alt="+" className="carManageAddImage2" />
                                                            </button>
                                                            <div className="carMBaseBorder" />
                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        1회

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{tucsonEmergency?.[0] ? tucsonEmergency?.[0]?.emergencyDate : '-'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{tucsonEmergency?.[0] ? tucsonEmergency?.[0]?.emergencyContent : '-'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorderTr" />

                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        2회

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{tucsonEmergency?.[1] ? tucsonEmergency?.[1]?.emergencyDate : '-'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{tucsonEmergency?.[1] ? tucsonEmergency?.[1]?.emergencyContent : '-'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorderTr" />

                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        3회

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{tucsonEmergency?.[2] ? tucsonEmergency?.[2]?.emergencyDate : '-'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{tucsonEmergency?.[2] ? tucsonEmergency?.[2]?.emergencyContent : '-'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorderTr" />

                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        4회

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{tucsonEmergency?.[3] ? tucsonEmergency?.[3]?.emergencyDate : '-'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{tucsonEmergency?.[3] ? tucsonEmergency?.[3]?.emergencyContent : '-'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorderTr" />

                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        5회

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{tucsonEmergency?.[4] ? tucsonEmergency?.[4]?.emergencyDate : '-'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{tucsonEmergency?.[4] ? tucsonEmergency?.[4]?.emergencyContent : '-'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorderTr" />

                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        6회

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{tucsonEmergency?.[5] ? tucsonEmergency?.[5]?.emergencyDate : '-'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{tucsonEmergency?.[5] ? tucsonEmergency?.[5]?.emergencyContent : '-'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorder" />
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                </Accordion>
                                        </tr>
                                    </tbody>
                            </table>
                        </div>

                        <div className="carMBaseList">
                            <table className="carMBaseTable">
                                <thead className="carMBaseThead">
                                    <tr>
                                        <th scope="col" className="px-4 py-3 relative bottom-4">
                                            CAR.02
                                            <span className="carBaseSpan">ASSEMBBLE CAR MANAGEMENT SYSTEM</span>
                                        </th>
                                    </tr>
                                </thead>
                                        
                                    <tbody>
                                        <tr className="carMBaseTbody">
                                            <tr>
                                                <th scope="row" className="carMBaseTbodyTh">
                                                <img src={lightCar} alt="" className="carMLightImg" />
                                                    차량종류

                                                </th>
                                                <td className="px-6 py-4 w-[140px]">
                                                        <span className="relative left-32">{morningInfo?.carName}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className="carMBaseTbodyTh">
                                                <img src={darkCar} alt="" className="carMDarkImg" />
                                                    차량정보

                                                </th>
                                                <td className="px-6 py-4 w-[140px]">
                                                        <span className="relative left-32">{morningInfo?.carNum}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className="carMBaseTbodyTh">
                                                <img src={lightCar} alt="" className="carMLightImg" />
                                                    취득일

                                                </th>
                                                <td className="px-6 py-4 w-[140px]">
                                                        <span className="relative left-32">{morningInfo?.carYear}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className="carMBaseTbodyTh">
                                                <img src={darkCar} alt="" className="carMDarkImg" />

                                                    연식

                                                </th>
                                                <td className="px-6 py-4 w-[140px]">
                                                        <span className="relative left-32">{morningInfo?.carYear.slice(0, 4)}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className="carMBaseTbodyTh">
                                                <img src={lightCar} alt="" className="carMLightImg" />

                                                    관리책임자

                                                </th>
                                                <td className="px-6 py-4 w-[140px]">
                                                        <span className="relative left-32">{morningInfo?.admin}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className="carMBaseTbodyTh">
                                                <img src={darkCar} alt="" className="carMDarkImg" />

                                                    사용상태

                                                </th>
                                                <td className="px-6 py-4 w-[140px]">
                                                    { morningInfo?.useStatus === true 
                                                        ? <span className="relative left-32">사용중</span>
                                                        : <span className="relative left-32 text-red-600">사용중지</span>
                                                    }
                                                        <button className="carTBaseTbodyTdButton outline-none" onClick={handleChangeMStatus}>
                                                            <span className="carTBaseTbodyTd">▶</span>
                                                        </button>
                                                </td>
                                            </tr>
                                                <Accordion allowZeroExpanded className="carMItemBg absolute">
                                                    <AccordionItem>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton className="carMItemButton">
                                                                <tr>
                                                                    <th scope="row" className="carMBaseTbodyTh">
                                                                    <img src={lightCar} alt="" className="carMLightImg" />

                                                                        최근정비내역

                                                                    </th>

                                                                    <td className="px-0 py-4 w-[140px]">
                                                                        <span className="relative left-32">{'부품 및 소모품 교환주기'}</span>
                                                                        <button className="carMItemDownButton outline-none">
                                                                            <span className="carMItemDownButtonSpan">▽</span>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <button className="carManageDetailsButton select-none" onClick={handleOpenMModal}>
                                                                <img src={Search} alt="+" className="carManageDetailsImage" />
                                                            </button>
                                                            <button className="carManageAddButton select-none ml-2" onClick={handleOpenAddM}>
                                                                <img src={userAdd} alt="+" className="carManageAddImage" />
                                                            </button>
                                                            <div className="carMBaseBorder" />
                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        엔진오일

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'10,000 Km'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'1년 2회'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorderTr" />

                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        오일필터

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'10,000 Km'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'1년 2회'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorderTr" />

                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        에어클리너

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'40,000 Km'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'-'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorderTr" />

                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        미션오일

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'20,000 Km'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'-'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorderTr" />

                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        브레이크오일

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'30,000 Km'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'매 2년'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorderTr" />

                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        파워스티어링오일

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'30,000 Km'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{'매 2년'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorder" />
                                                        </AccordionItemPanel>
                                                    </AccordionItem>

                                                    <AccordionItem>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton className="carMItemButton">
                                                                <tr>
                                                                    <th scope="row" className="carMBaseTbodyTh">
                                                                    <img src={lightCar} alt="" className="carMLightImg" />

                                                                        긴급출동서비스

                                                                    </th>

                                                                    <td className="px-0 py-4 w-[140px]">
                                                                        <span className="relative left-32">{'사유 및 서비스 사용일자'}</span>
                                                                        <button className="carMItemDownButton outline-none">
                                                                            <span className="carMItemDownButtonSpan">▽</span>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <button className="carManageAddButton2 select-none" onClick={handleOpenEM}>
                                                                <img src={userAdd} alt="+" className="carManageAddImage2" />
                                                            </button>
                                                            <div className="carMBaseBorder" />
                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        1회

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{morningEmergency?.[0] ? morningEmergency?.[0]?.emergencyDate : '-'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{morningEmergency?.[0] ? morningEmergency?.[0]?.emergencyContent : '-'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorderTr" />

                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        2회

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{morningEmergency?.[1] ? morningEmergency?.[1]?.emergencyDate : '-'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{morningEmergency?.[1] ? morningEmergency?.[1]?.emergencyContent : '-'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorderTr" />

                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        3회

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{morningEmergency?.[2] ? morningEmergency?.[2]?.emergencyDate : '-'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{morningEmergency?.[2] ? morningEmergency?.[2]?.emergencyContent : '-'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorderTr" />

                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        4회

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{morningEmergency?.[3] ? morningEmergency?.[3]?.emergencyDate : '-'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{morningEmergency?.[3] ? morningEmergency?.[3]?.emergencyContent : '-'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorderTr" />

                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        5회

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{morningEmergency?.[4] ? morningEmergency?.[4]?.emergencyDate : '-'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{morningEmergency?.[4] ? morningEmergency?.[4]?.emergencyContent : '-'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorderTr" />

                                                            <tr>
                                                                <th scope="row" className="carMBaseTbodyTh">

                                                                        6회

                                                                </th>

                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{morningEmergency?.[5] ? morningEmergency?.[5]?.emergencyDate : '-'}</span>
                                                                </td>
                                                                <td className="px-0 py-4 w-[140px]">
                                                                    <span className="relative left-32">{morningEmergency?.[5] ? morningEmergency?.[5]?.emergencyContent : '-'}</span>
                                                                </td>
                                                            </tr>
                                                            <div className="carMBaseBorder" />
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                </Accordion>
                                            </tr>
                                    </tbody>
                            </table>
                            <CarInspectionTModal inspectionModalTIsOpen={inspectionModalTIsOpen} setInspectionModalTIsOpen={setInspectionModalTIsOpen} />
                            <CarInspectionMModal inspectionModalMIsOpen={inspectionModalMIsOpen} setInspectionModalMIsOpen={setInspectionModalMIsOpen} />
                            <CarCheckAddTButton addModalTIsOpen={addModalTIsOpen} setAddModalTIsOpen={setAddModalTIsOpen} />
                            <CarCheckAddMButton addModalMIsOpen={addModalMIsOpen} setAddModalMIsOpen={setAddModalMIsOpen} />
                            <CarEmergencyAddTButton emergencyModalTIsOpen={emergencyModalTIsOpen} setEmergencyModalTIsOpen={setEmergencyModalTIsOpen} />
                            <CarEmergencyAddMButton emergencyModalMIsOpen={emergencyModalMIsOpen} setEmergencyModalMIsOpen={setEmergencyModalMIsOpen} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CarManagement;