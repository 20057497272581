import { addYears, addMonths } from "date-fns";
import axios from "axios"
import { reservationsPutAxios } from "./reservationsPutAxios"


const startYears = addYears(new Date(), -1);
const endMonth = addMonths(new Date(), 1);

export function reservationsPostAxios(resData) {
        return async function reservationsPostThunk(dispatch, getState) {
            await axios.post(`/api/reservations`, resData)
            dispatch(reservationsPutAxios({ startdate: startYears, enddate: endMonth,}))
        }
    }