import { createSlice } from '@reduxjs/toolkit'


export const usersSlice = createSlice({
    name: 'users',
    initialState: {
        usersData: [],
    },
    reducers: {
        getUsers: (state, action) => {
            return {...state, usersData: [...action.payload]}
        },
        addUsers: (state, action) => {
            state.resData = action.payload
        },
        deleteUsers: (state, action) => {
            state.resData = action.payload
        },
        modifyUsers: (state, action) => {
            state.resData = action.payload
        },
        modifyTelephone: (state, action) => {
            state.resData = action.payload
        },
        modifyPassword: (state, action) => {
            state.resData = action.payload
        },
    },
})

export const { getUsers, addUsers, deleteUsers, modifyUsers, modifyTelephone, modifyPassword } = usersSlice.actions;
export const usersData = state => state?.users?.usersData;
export default usersSlice.reducer;