import 'css/UserManagement.css';
import DialogBox from 'react-modeless';
import axios from 'axios';
import useFieldValues from 'base/hook/useFieldValues';
import 'css/Modal.css';
import 'css/Validator.css';
import UserId from 'static/image/ResUsers747.png';
import UserPw from 'static/image/Pw198.png';
import UserName from 'static/image/ResUsers747.png';
import UserPart from 'static/image/ResPurpose769.png';
import UserPhone from 'static/image/Phone754.png';
import UserAuth from 'static/image/ResPD756.png';
import UserAddImage from 'static/image/ModifyImage.png';


const INIT_FILED_VALUES = {
    authority: 0,
    partname: '',
    password: '',
    position: '',
    telephone: '',
    user_id: '',
    username: '',
}

function UserAddButton({ userAddModalIsOpen, setUserAddModalIsOpen}) {
    const { 
        fieldValues, 
        handleFieldChange, 
        formErrors,
        emptyFieldValues,
    } = useFieldValues(INIT_FILED_VALUES);

    const addUserButton = (e) => {
        e.preventDefault();
                
        axios({
            method: 'post',
            url: '/api/users',
            data: { ...fieldValues, user_id: parseInt(fieldValues.user_id) }
        }).then(function(response) {
            setUserAddModalIsOpen(false);
            window.location.reload();
        }).catch(function(error) {
            console.log(error)
        });
    }

    return (
        <div>
            <DialogBox            
                ariaHideApp={false}
                isOpen={userAddModalIsOpen}
                onRequestClose={() => setUserAddModalIsOpen(false)}
                shouldCloseOnOverlayClick={false}
                className="userAddModal shadow-md outline-none"
                style={{
                    backgroundColor: 'rgba(58, 61, 63, 0.8)',
                    width: '100%',
                    height: '100vh',
                }}
            >
                <form onSubmit={addUserButton} className="UserAddModal">
                    <div className="UserAddModalBg">
                        <div className="absolute top-[135px] right-[625px]">
                            <button className="UserAddXButton" onClick={() => [emptyFieldValues(), setUserAddModalIsOpen(false)]}>
                                <span className="UserAddXButtonSpan">X</span>
                            </button>
                        </div>
                        <div className="UserAddModalBB">
                            <span className='UserAddModalSpan1'>+</span>
                            <span className="UserAddModalSpan2">회원 추가</span>
                        </div>

                        <div className="UserAddIdSet flex">
                            <img src={UserId} alt="◽" className="UserAddIdImage" />
                            <label htmlFor="user_id" className="UserAddIdLabel">아이디</label>
                            <input type="user_id" name="user_id" id="user_id" value={fieldValues.user_id} onChange={handleFieldChange} className="UserAddIdInput" required={true} maxLength='20' pattern="^[0-9]+$" title="사번을 숫자로 작성해주세요" placeholder='예) 201200000' />
                        </div>

                        <div className="UserAddPWSet flex">
                            <img src={UserPw} alt="◽" className="UserAddPWImage" />
                            <label htmlFor="password" className="UserAddPWLabel">비밀번호</label>
                            <input type="password" name="password" id="password" value={fieldValues.password} onChange={handleFieldChange} className="UserAddPWInput" required={true} placeholder='*********' />
                        </div>

                        <div className="UserAddNameSet flex">
                            <img src={UserName} alt="◽" className="UserAddNameImage" />
                            <label htmlFor="username" className="UserAddNameLabel">이름</label>
                            <input type="username" name="username" id="username" className="UserAddNameInput" value={fieldValues.username} onChange={handleFieldChange} required={true} maxLength='10' pattern="^[가-힣]+$" title="회원명을 한글로 작성해주세요" placeholder='예) 관리자' />
                        </div>

                        <div className="UserAddPartSet flex">
                            <img src={UserPart} alt="◽" className="UserAddPartImage" />
                            <label htmlFor="partname" className="UserAddPartLabel">소속</label>
                            <input type="partname" name="partname" id="partname" className="UserAddPartInput" value={fieldValues.partname} onChange={handleFieldChange} required={true} maxLength='20' pattern="^[가-힣\s]+$" title="부서명을 한글로 작성해주세요" placeholder='예) 경영지원부' />
                        </div>

                        <div className="UserAddPositionSet flex">
                            <img src={UserPart} alt="◽" className="UserAddPartImage" />
                            <label htmlFor="position" className="UserAddPositionLabel">직위</label>
                            <input type="position" name="position" id="position" className="UserAddPositionInput" value={fieldValues.position} onChange={handleFieldChange} required={true} maxLength='10' pattern="^[가-힣\s]+$" title="직위를 한글로 작성해주세요" placeholder='예) 관리자' />
                        </div>

                        <div className="UserAddPhoneSet flex">
                            <img src={UserPhone} alt="◽" className="UserAddPhoneImage" />
                            <label htmlFor="telephone" className="UserAddPhoneLabel">전화번호</label>
                            <input type="telephone" name="telephone" id="telephone" value={fieldValues.telephone} onChange={handleFieldChange} className="UserAddPhoneInput" required={true} maxLength='11' pattern="^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$" title="휴대폰 번호를 숫자로 작성해주세요" placeholder='예) 00011112222' />
                        </div>

                        <div className="UserAddAuthoritySet flex">
                            <img src={UserAuth} alt="◽" className="UserAddAuthImage" />
                            <label htmlFor="authority" className="UserAddAuthLabel">권한</label>
                            <select type="authority" name="authority" id="authority" key={0} defaultValue={0} value={fieldValues.authority} onChange={handleFieldChange} className="UserAddAuthSelect">
                                <option value={1}>관리자</option>
                                <option value={0}>일반회원</option>
                            </select>
                        </div>

                        <div className="UserAddButtonSet flex justify-end">
                            <button className="UserAddCancelButton" type="button" onClick={() => [emptyFieldValues(), setUserAddModalIsOpen(false)]}>
                                <span className="UserAddCancelSpan">취소</span>
                            </button>
                            <button className="UserAddButton" type="submit">
                                <span className="UserAddSpan">등록</span>
                            </button>
                        </div>

                        <img src={UserAddImage} alt="" className="UserAddImage" />
                    </div>
                </form>
            </DialogBox>
        </div>
    )
}

export default UserAddButton;