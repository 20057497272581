import DatePicker from "react-datepicker";
import { ko } from 'date-fns/esm/locale';
import { addDays, subDays } from 'date-fns';
import { useEffect, useState } from "react";
import moment from "moment";
import useFieldValues from "base/hook/useFieldValues";
import { useAuth } from "base/hook/Authcontext";
import DialogBox from 'react-modeless'
import { useDispatch, useSelector } from "react-redux";
import { reservationsPostAxios } from "base/redux/reservations/reservationPostAxios";
import { resData } from "base/redux/reservations/reservationsSlice";
import ResUser from 'static/image/ResUsers747.png';
import ResCar from 'static/image/ResCars767.png';
import ResPurpose from 'static/image/ResPurpose769.png';
import ResPD from 'static/image/ResPD756.png';
import ResModalCar from 'static/image/ResModalCar771.png';
import 'css/DatePicker.css';
import 'css/AdminResButton.css';
import { usersGetAxios } from "base/redux/accounts/usersGetAxios";
import { usersData } from "base/redux/accounts/usersSlice";


const INIT_FILED_VALUES = {
    carId: '1',
    purpose: '0',
    purposeDetail:'',
}


function AdminResButton({ adminResModalIsOpen, setAdminResModalIsOpen }) {
    const dispatch = useDispatch();
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [startDateTime, setStartDateTime] = useState();
    const [endDateTime, setEndDateTime] = useState();
    const [startClassName, setStartClassName] = useState();
    const [endClassName, setEndClassName] = useState();
    const [selectCar, setSelectCar] = useState(1);
    const [selectPartname, setSelectPartname] = useState(0);
    const [selectUser, setSelectUser] = useState(0);
    const resInfo = useSelector(resData);
    const userInfo = useSelector(usersData);
    const partSet = new Set(userInfo?.map(({partname}) => partname));
    const formatDate = (inputDate, formatString = 'YYYY-MM-DD') => {return moment(inputDate).format(formatString)};
        
    const parsedResInfos = resInfo
        ?.filter(({ carId }) => carId === selectCar)
        ?.map(({ reservationId, startdate, enddate }) => ({
        reservationId,
            startdate: new Date(startdate).getTime(),
            enddate: new Date(enddate).getTime()
        }))
        .filter(
            ({ startdate, enddate }) =>
            !(
                enddate < startDate ||
                startdate >= startDate + 24 * 3600 * 1000
            )
        );

    const withoutTimezone = (time) => {
        const dateObjectTime = new Date(time);
            
        const hour = dateObjectTime.getHours();
        const minute = dateObjectTime.getMinutes();
            
            return (hour * 3600 + minute * 60) * 1000;
    };

    const startFilterPassedTime = (time) => {
        // 선택된 시작일과 받아온 time 객체를 timestamp 형식으로 결합
        const parsedTime = startDate + withoutTimezone(time);
        // startdate와 enddate의 범위 안에 time이 있는지 확인
        const currentDate = new Date();
        
        const timeFilter = ({ startdate, enddate }) =>
        parsedTime >= startdate && parsedTime <= enddate;
            
        // array에 있는 모든 예약건과 겹치지 않아야 true 반환
        return !parsedResInfos.some(timeFilter) && currentDate.getTime() < parsedTime;
    };

    const endFilterPassedTime = (time) => {
        // 선택된 시작일과 받아온 time 객체를 timestamp 형식으로 결합
        const parsedTime = endDate + withoutTimezone(time);
        // startdate와 enddate의 범위 안에 time이 있는지 확인
        const currentDate = new Date();
        
        const timeFilter = ({ startdate, enddate }) =>
        parsedTime >= startdate && parsedTime <= enddate;
            
        // array에 있는 모든 예약건과 겹치지 않아야 true 반환
        return !parsedResInfos.some(timeFilter) && currentDate.getTime() < parsedTime ;
    };

    const { 
        fieldValues, 
        handleFieldChange, 
        emptyFieldValues,
        formErrors,
    } = useFieldValues(INIT_FILED_VALUES);

    const onFetchClicked = (e) => {
        e.preventDefault();

        dispatch(reservationsPostAxios({...fieldValues, userId: selectUser, startdate: startDateTime, enddate: endDateTime, carId: parseInt(fieldValues.carId) }))
    }

    const handleSelectCar = (e) => {
        setSelectCar(parseInt(e.target.value));
    }

    const handleSelectPartname = (e) => {
        setSelectPartname(e.target.value);
    }

    const handleSelectUser = (e) => {
      setSelectUser(parseInt(e.target.value));
    }

    useEffect(() => {
        setAdminResModalIsOpen(false);
        emptyFieldValues(); 
        setStartDate(''); 
        setStartTime(''); 
        setEndDate(''); 
        setEndTime('');
    }, [resInfo])

    useEffect(() => {
        const startTimestamp = startDate + withoutTimezone(startTime);
        const endTimestamp = endDate + withoutTimezone(endTime);

    
        setStartDateTime(moment(new Date(startTimestamp)).format("YYYY-MM-DD HH:mm"));
        setEndDateTime(moment(new Date(endTimestamp)).format('YYYY-MM-DD HH:mm'));
    }, [startDate, startTime, endDate, endTime]);

    useEffect(() => {
        dispatch(usersGetAxios());
    }, []);

    return(
        <>
            <DialogBox
                isOpen={adminResModalIsOpen}
                onClose={() => setAdminResModalIsOpen(false)}
                noBackdrop={true}
                clickBackdropToClose={false}
                style={{
                    backgroundColor: 'rgba(58, 61, 63, 0.8)',
                    width: '100%',
                    height: '100vh',
                }}
            >        
                <form onSubmit={onFetchClicked} className="ResModal">
                    <div className="ResModalBg">
                        <div className="absolute top-[5px] right-[5px]">
                            <button className="ResXButton" onClick={() => [emptyFieldValues(), setAdminResModalIsOpen(false), setStartDate(''), setStartTime(''), setEndDate(''), setEndTime(''), setSelectPartname(0)]}>
                                <span className="ResXButtonSpan">X</span>
                            </button>
                        </div>

                        <div className="ResModalBB">
                            <span className='ResModalP'>+</span>
                            <span className="ResModalSpan">예약하기</span>
                        </div>

                        <div className="ResNameSet flex" value={fieldValues.user_id} onChange={handleFieldChange} required={true}>
                            <img src={ResUser} alt="👓" className="ResNameImage" />
                            <label htmlFor="username" className="ResNameLabel">사용자</label>
                            <select type="partname" name="partname" id="partname" defaultValue={0} onChange={handleSelectPartname} className="ResPartnameSelect">
                                <option value={0}>----------------</option>
                            { Array.from(partSet)?.filter((partname) => partname !== '관리자' && partname !== '대표이사')?.sort()?.map(({ user_id, partname }) =>  
                                <option value={partname}>{partname}</option>
                            )}
                            </select>
                            <select type="user_id" name="user_id" id="user_id" defaultValue={0} onChange={handleSelectUser} className="ResUser_idSelect">
                                <option value={0}>----------------</option>
                            { userInfo?.filter(({ partname }) => partname === selectPartname)?.map(({ user_id, username }) => 
                                <option value={user_id}>{username}</option>
                            )}
                            </select>
                        </div>


                        <div className="ResCarSelectSet flex" value={fieldValues.carId} onChange={handleFieldChange} required={true}>
                            <img src={ResCar} alt="🚕" className="ResCarImage" />
                            <label htmlFor="carId" className="ResCarLabel">차량</label>
                            <select type="carId" name="carId" id="carId" key={0} defaultValue={1} onChange={handleSelectCar} className="ResCarSelect">
                                <option value={2}>모닝</option>
                                <option value={1}>투싼</option>
                            </select>
                        </div>

                        <div className="ResStartSet flex">
                            <div className="ResStartBB">
                                <label htmlFor="" className="ResStartLabel">이용시작</label>
                            </div>
                            <DatePicker 
                                type="startDate"
                                id="startDate"
                                name="startDate"
                                locale={ko}
                                selected={startDate}
                                onChange={(date) => setStartDate(new Date(date).getTime())}
                                minDate={new Date()}
                                maxDate={addDays(new Date(), 7)}
                                dateFormat="yyyy-MM-dd"
                                dateFormatCalendar="yyyy년 MM월"
                                required={true}
                                autoComplete="off"
                                showPopperArrow={false}
                                popperPlacement="auto"
                                popperClassName="startPopper"
                                excludeDateIntervals={resInfo
                                    ?.filter(
                                        ({ carId, startdate, enddate }) => carId === parseInt(selectCar) && formatDate(startdate) < formatDate(enddate)
                                    )
                                    .map(({ startdate, enddate }) => {
                                        return {
                                            start: subDays(new Date(startdate), 1),
                                            end: addDays(new Date(enddate), -1),
                                        };
                                    })}
                                selectsDisabledDaysInRange
                                className="StartDatePicker" 
                                onCalendarOpen={() => setStartClassName(true)}
                                onCalendarClose={() => setStartClassName(false)}
                            />
                            <DatePicker 
                                type="startTime"
                                id="startTime"
                                name="startTime"
                                selected={startTime}
                                onChange={(time) => setStartTime(time)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={30}
                                timeCaption="시작 시간"
                                dateFormat="HH:mm"
                                required={true}
                                autoComplete="off"
                                showPopperArrow={false}
                                popperPlacement="auto"
                                popperClassName="startPopper"
                                filterTime={startFilterPassedTime}
                                className="StartTimePicker" 
                                onCalendarOpen={() => setStartClassName(true)}
                                onCalendarClose={() => setStartClassName(false)}
                            />
                        </div>

                        <div className="ResEndSet flex">
                            <div className="ResEndBB">
                                <label htmlFor="" className="ResEndLabel">이용종료</label>
                            </div>
                            <DatePicker 
                                type="endDate"
                                id="endDate"
                                name="endDate"
                                locale={ko}
                                selected={endDate}
                                onChange={(date) => setEndDate(new Date(date).getTime())}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                maxDate={addDays(new Date(startDate), 5)}
                                dateFormat="yyyy-MM-dd"
                                dateFormatCalendar="yyyy년 MM월"
                                required={true}
                                autoComplete="off"
                                showPopperArrow={false}
                                popperPlacement="auto"
                                excludeDateIntervals={resInfo
                                    ?.filter(
                                        ({ carId, startdate, enddate }) => carId === parseInt(selectCar) && formatDate(startdate) < formatDate(enddate)
                                    )
                                    .map(({ startdate, enddate }) => {
                                        return {
                                            start: subDays(new Date(startdate), 1),
                                            end: formatDate(startdate) < formatDate(enddate) ? addDays(new Date(enddate), -1) : addDays(new Date(enddate), 6),
                                        };
                                    })}
                                className="EndDatePicker" 
                                onCalendarOpen={() => setEndClassName(true)}
                                onCalendarClose={() => setEndClassName(false)}
                                disabled={!startDate}
                            />
                            <DatePicker 
                                type="endTime"
                                id="endTime"
                                name="endTime"
                                selected={endTime}
                                onChange={(time) => setEndTime(time)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={30}
                                timeCaption="종료 시간"
                                dateFormat="HH:mm"
                                required={true}
                                autoComplete="off"
                                showPopperArrow={false}
                                popperPlacement="auto"
                                filterTime={endFilterPassedTime}
                                className="EndTimePicker" 
                                onCalendarOpen={() => setEndClassName(true)}
                                onCalendarClose={() => setEndClassName(false)}
                                disabled={!startTime}
                            />
                        </div>

                        <div className="ResPurposeSet flex" value={fieldValues.purpose} onChange={handleFieldChange} required={true}>
                            <img src={ResPurpose} alt="📝" className="ResPurposeImage" />
                            <label htmlFor="purpose" className="ResPurposeLabel">이용목적</label>
                            <select type="purpose" name="purpose" id="purpose" key={0} defaultValue={0} className="ResPurposeSelect text-black">
                                <option value={1}>점검</option>
                                <option value={0}>출장</option>
                            </select>
                        </div>

                        <div className="ResPDSet" required={true}>
                            <div className="flex">
                                <img src={ResPD} alt="📄" className="ResPDImage" />
                                <label htmlFor="purposeDetail" className="ResPDLabel">상세이용목적</label>
                            </div>
                            <input type="purposeDetail" name="purposeDetail" id="purposeDetail" value={fieldValues.purposeDetail} onChange={handleFieldChange} className="ResPDTextarea" required={true} maxLength='100' pattern="^[가-힣\s]+$" title="한글 단어로 작성해주세요" />
                        </div>
                            {/* 글자수 => maxlength / 한글 => 패턴으로 설정 ==> useFieldValues에 설정한 유효성검사로 교체....textarea로 교체.......... */}

                        <div className="ResModalButtonSet flex justify-end">
                            <button className="ResModalCancelButton" type="button" onClick={() => [emptyFieldValues(), setAdminResModalIsOpen(false), setStartDate(''), setStartTime(''), setEndDate(''), setEndTime(''), setSelectPartname(0)]}>
                                <span className="ResModalCancelSpan">취소</span>
                            </button>
                            <button className="ResModalResButton" type="submit">
                                <span className="ResModalResSpan">예약</span>
                            </button>
                        </div>

                        <img src={ResModalCar} alt="🚘" className="ResModalCarImage" />
                    </div>
                </form>
            </DialogBox>
        </>
    )
}

export default AdminResButton;