import { createSlice } from "@reduxjs/toolkit"

export const partnameSlice = createSlice({
  name: 'partname',
  initialState: {
      partData: [],
  },
  reducers: {
      partnamePut: (state, action) => {
          return {...state, partData: [...action.payload]}
      },
  },
})

export const { partnamePut } = partnameSlice.actions;
export const partData = state => state?.partname?.partData;
export default partnameSlice.reducer;