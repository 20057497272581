import { usersGetAxios } from 'base/redux/accounts/usersGetAxios';
import { reservationsPutAxios } from 'base/redux/reservations/reservationsPutAxios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addYears, addMonths } from 'date-fns';
import 'css/DashBoard.css'
import { partnamePutAxios } from 'base/redux/reservations/partnamePutAxios';
import { partData } from 'base/redux/reservations/partnameSlice';
import { resData } from 'base/redux/reservations/reservationsSlice';


const PartNameInfo = ['경영지원부', '영업본부', '컨설팅사업부', 'IoT 연구소']

function DashNumber() {
  const dispatch = useDispatch();
  const partInfo = useSelector(partData);
  const resInfo = useSelector(resData);
  const startYear = addYears(new Date(), -1);
  const endMonth = addMonths(new Date(), 1);
  const years = new Date().getFullYear();
  const [AssembbleTeams, setAssembbleTeams] = useState([]);

  useEffect(() => {
    dispatch(reservationsPutAxios({ startdate: startYear, enddate: endMonth }))
    dispatch(partnamePutAxios({ startdate: years + '-01-01', enddate: years + '-12-31' }))
    dispatch(usersGetAxios())
  }, []);

  useEffect(() => {
    setAssembbleTeams([]);
    let AiCount = 0;
    let StrategicCount = 0;

    partInfo
      ?.filter(({ partname }) => partname?.includes("AI"))
      ?.map(({ count }) => (AiCount += count));

    partInfo
      ?.filter(({ partname }) => partname?.includes("디자인"))
      ?.map(({ count }) => (AiCount += count));

    partInfo
      ?.filter(({ partname }) => partname?.includes("전략"))
      ?.map(({ count }) => (StrategicCount += count));

    PartNameInfo?.map((department) => {
      const departmentInfo = partInfo?.find(
        ({ partname }) => partname === department
      );

      setAssembbleTeams((prev) => [
        ...prev,
        {
          partname: department,
          count: departmentInfo ? departmentInfo.count : 0
        }
      ]);
    });

    setAssembbleTeams((prev) => [{partname: "전략기획본부", count: StrategicCount }, ...prev, { partname: "AI 연구소", count: AiCount }]);
  }, [partInfo]);

  return (
    <div className="DashNumberBg">
      <div className="DashNumberBB select-none">
        <span className='DashNumberSpan1'>+</span>
        <span className="DashNumberSpan2">TOTAL NUMBER</span>
      </div>

      { AssembbleTeams?.map(({ partname, count }) => 
        <div className='DashNumberSet' key={partname}>
          <div className='DashNumberCircle'>
            <span className='DashNumberNum'>{count}</span>
          </div>
          <p className='DashNumberTeam'>{partname}</p>
        </div>
      )}
    </div>
  )
}

export default DashNumber;