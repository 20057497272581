import { configureStore } from '@reduxjs/toolkit';
import reservationsReducer from './reservations/reservationsSlice';
import usersReducer from './accounts/usersSlice';
import returnReducer from './return/returnSlice';
import partnameReducer from './reservations/partnameSlice';
import carsReducer from './cars/carsSlice';
import checkReducer from './check/checkSlice';
import emergencyReducer from './emergency/emergencySlice';


export default configureStore({ 
    reducer: {
        reservations: reservationsReducer,
        users: usersReducer,
        return: returnReducer,
        partname: partnameReducer,
        cars: carsReducer,
        check: checkReducer,
        emergency: emergencyReducer,
    }, 
})