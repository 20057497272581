import LoginForm from "components/main/accounts/LoginForm";
import Login393 from 'static/image/Login393.png';
import Login3932 from 'static/image/Login393.jpg';
import 'css/Login.css';


function LoginPage() {
    return (
        <div className="LoginPageBg w-screen h-screen select-none">
            <img src={Login393} className="LoginImage1 h-screen w-screen" />
            <img src={Login3932} className="LoginImage2 h-screen w-screen" />
            <LoginForm />
        </div>
    )
}

export default LoginPage;