import axios from "axios"
import { checkPutAxios } from "./checkPutAxios";

const years = new Date().getFullYear();
const startYears = years + '-01-01';
const endMonth = years + '-12-31';

export function checkPostAxios(checkData) {
        return async function checkPostThunk(dispatch, getState) {
            await axios.post(`/api/check`, checkData)
            dispatch(checkPutAxios({ startdate: startYears, enddate: endMonth,}))
        }
    }