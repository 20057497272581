import { createSlice } from '@reduxjs/toolkit'


export const emergencySlice = createSlice({
    name: 'emergency',
    initialState: {
      emergencyData: [],
    },
    reducers: {
        putEmergency: (state, action) => {
            return {...state, emergencyData: [...action.payload]}
        },
        addEmergency: (state, action) => {
            state.emergencyData = action.payload
        },
    },
})

export const { putEmergency, addEmergency } = emergencySlice.actions;
export const emergencyData = state => state?.emergency?.emergencyData;
export default emergencySlice.reducer;