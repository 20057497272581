import useFieldValues from "base/hook/useFieldValues";
import DialogBox from 'react-modeless'
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ReturnModalCar from 'static/image/ResModalCar771.png';
import ReturnDate from 'static/image/Return650.png';
import 'css/ResReturnButton.css';
import { useEffect } from "react";
import 'css/CarInspectionModal.css';
import { emergencyPostAxios } from "base/redux/emergency/emergencyPostAxios";
import { emergencyData } from "base/redux/emergency/emergencySlice";


const INIT_FILED_VALUES = {
  carId: '',
  emergencyDate: moment(new Date()).format('YYYY-MM-DD'),
  emergencyContent: '',
}


function CarEmergencyAddTButton ({ emergencyModalTIsOpen, setEmergencyModalTIsOpen}) {
    const dispatch = useDispatch();
    const emergencyInfo = useSelector(emergencyData);

    const { 
        fieldValues, 
        handleFieldChange, 
        emptyFieldValues,
    } = useFieldValues(INIT_FILED_VALUES);
    
    const onFetchClicked = (e) => {
        e.preventDefault();

        dispatch(emergencyPostAxios({ ...fieldValues, carId: 1, emergencyContent: fieldValues.emergencyContent }))
    }

    useEffect(() => {
        emptyFieldValues(); 
        setEmergencyModalTIsOpen(false);
    }, [emergencyInfo])

    return(
        <>
            <DialogBox
                ariaHideApp={false}
                isOpen={emergencyModalTIsOpen}
                onRequestClose={() => setEmergencyModalTIsOpen(false)}
                shouldCloseOnOverlayClick={false}
                clickBackdropToClose={false}
                style={{
                    backgroundColor: 'rgba(58, 61, 63, 0.8)',
                    width: '100%',
                    height: '100vh',
                }}  
            >

            <form onSubmit={onFetchClicked} className="checkModal">
                <div className="emergencyModalBg">
                    <div className="absolute top-[60px] right-[655px]">
                        <button className="checkXButton" onClick={() => [emptyFieldValues(), setEmergencyModalTIsOpen(false)]}>
                            <span className="ReturnXButtonSpan">X</span>
                        </button>
                    </div>
                    <div className="ReturnModalBB">
                        <span className='ReturnModalSpan1'>+</span>
                        <span className="emergencyModalSpan2">긴급출동서비스 등록</span>
                    </div>

                    <div className="flex">
                        <img src={ReturnDate} alt="◽" className="ReturnDateImage" />
                        <label htmlFor="" className="ReturnDateLabel">등록일</label>
                        <input type="text" placeholder={moment(new Date()).format('YYYY-MM-DD')} disabled={true} className="ReturnDateInput" />
                    </div>

                    <div className="checkDamageSet">
                        <div className="ReturnDamageBB flex">
                            <label htmlFor="emergencyContent" className="ReturnDamageLabel">출동상세내용</label>
                        </div>
                        <textarea type="emergencyContent" name="emergencyContent" id="emergencyContent" placeholder="서비스 출동에 대한 상세 내용을 적어주세요" required={true} className="checkDamageText" value={fieldValues.emergencyContent} onChange={handleFieldChange} />
                    </div>

                    <div className="checkButtonSet flex justify-end">
                        <button className="ReturnCancelButton" type="button" onClick={() => [emptyFieldValues(), setEmergencyModalTIsOpen(false)]}>
                            <span className="ReturnCancelSpan">취소</span>
                        </button>
                        <button className="ReturnButton" type="submit">
                            <span className="ReturnSpan">등록</span>
                        </button>
                    </div>

                    <img src={ReturnModalCar} alt="🚘" className="ReturnModalCarImage" />
                </div>
            </form>
        </DialogBox>
        </>
    )
}

function CarEmergencyAddMButton ({ emergencyModalMIsOpen, setEmergencyModalMIsOpen}) {
  const dispatch = useDispatch();
  const emergencyInfo = useSelector(emergencyData);

  const { 
      fieldValues, 
      handleFieldChange, 
      emptyFieldValues,
  } = useFieldValues(INIT_FILED_VALUES);
  
  const onFetchClicked = (e) => {
      e.preventDefault();

      dispatch(emergencyPostAxios({ ...fieldValues, carId: 2, emergencyContent: fieldValues.emergencyContent }))
  }

  useEffect(() => {
      emptyFieldValues(); 
      setEmergencyModalMIsOpen(false);
  }, [emergencyInfo])

  return(
      <>
          <DialogBox
              ariaHideApp={false}
              isOpen={emergencyModalMIsOpen}
              onRequestClose={() => setEmergencyModalMIsOpen(false)}
              shouldCloseOnOverlayClick={false}
              clickBackdropToClose={false}
              style={{
                  backgroundColor: 'rgba(58, 61, 63, 0.8)',
                  width: '100%',
                  height: '100vh',
              }}  
          >

          <form onSubmit={onFetchClicked} className="checkModal">
              <div className="checkModalBg">
                  <div className="absolute top-[60px] right-[655px]">
                      <button className="checkXButton" onClick={() => [emptyFieldValues(), setEmergencyModalMIsOpen(false)]}>
                          <span className="ReturnXButtonSpan">X</span>
                      </button>
                  </div>
                  <div className="ReturnModalBB">
                        <span className='ReturnModalSpan1'>+</span>
                        <span className="emergencyModalSpan2">긴급출동서비스 등록</span>
                    </div>

                    <div className="flex">
                        <img src={ReturnDate} alt="◽" className="ReturnDateImage" />
                        <label htmlFor="" className="ReturnDateLabel">등록일</label>
                        <input type="text" placeholder={moment(new Date()).format('YYYY-MM-DD')} disabled={true} className="ReturnDateInput" />
                    </div>

                    <div className="checkDamageSet">
                        <div className="ReturnDamageBB flex">
                            <label htmlFor="emergencyContent" className="ReturnDamageLabel">출동상세내용</label>
                        </div>
                        <textarea type="emergencyContent" name="emergencyContent" id="emergencyContent" placeholder="서비스 출동에 대한 상세 내용을 적어주세요" required={true} className="checkDamageText" value={fieldValues.emergencyContent} onChange={handleFieldChange} />
                    </div>

                  <div className="checkButtonSet flex justify-end">
                      <button className="ReturnCancelButton" type="button" onClick={() => [emptyFieldValues(), setEmergencyModalMIsOpen(false)]}>
                          <span className="ReturnCancelSpan">취소</span>
                      </button>
                      <button className="ReturnButton" type="submit">
                          <span className="ReturnSpan">등록</span>
                      </button>
                  </div>

                  <img src={ReturnModalCar} alt="🚘" className="ReturnModalCarImage" />
              </div>
          </form>
      </DialogBox>
      </>
  )
}

export { CarEmergencyAddTButton, CarEmergencyAddMButton } ;