import { createSlice } from '@reduxjs/toolkit'


export const returnSlice = createSlice({
    name: 'return',
    initialState: {
        returnData: [],
    },
    reducers: {
        getReturn: (state, action) => {
            return {...state, returnData: [...action.payload]}
        },
        addReturn: (state, action) => {
            state.resData = action.payload
        },
    },
})
export const { getReturn, addReturn } = returnSlice.actions;
export const returnData = state => state?.return.returnData;
export default returnSlice.reducer;