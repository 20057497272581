import { CSVLink } from 'react-csv'
import moment from "moment";
import ExcelDown from 'static/image/ExcelDown.png';
import { useDispatch, useSelector } from "react-redux";
import { resData } from 'base/redux/reservations/reservationsSlice';
import { useEffect } from 'react';
import { reservationsPutAxios } from 'base/redux/reservations/reservationsPutAxios';
import { usersData } from 'base/redux/accounts/usersSlice';
import { usersGetAxios } from 'base/redux/accounts/usersGetAxios';


function Excel(optionCarId) {
    const years = new Date().getFullYear();
    const dispatch = useDispatch();
    const resInfo = useSelector(resData);
    const userInfo = useSelector(usersData);

    useEffect(() => {
        dispatch(reservationsPutAxios({ startdate: years + '-01-01', enddate: years + '-12-31' }))
        dispatch(usersGetAxios())
    }, []);

    const headers = [
        { label: '차량', key: 'car' },
        { label: '사용자', key: 'userId' },
        { label: '대여일시', key: 'start' },
        { label: '반납일시', key: 'end' },
        { label: '목적지', key: 'destination' },
        { label: '주행거리', key: 'distance' },
        { label: '누적주행거리', key: 'mileage' },
        { label: '차량외관', key: 'damage' },
        { label: '조명장치', key: 'lighting' },
        { label: '계기판', key: 'dashboard' },
        { label: '기타', key: 'besides' },
        { label: '주유금액', key: 'refueling' },
        { label: '주차위치', key: 'parking' },
    ]

    const data = 
    resInfo?.filter(({ rentalState }) => rentalState === 1)?.map(({ carId, userId, startdate, enddate, destination, distance, mileage, damage, lighting, dashboard, besides, refueling_amount, parking  }) => 
            ({
                car: carId === 1 ? '투싼' : '모닝', 
                userId: userInfo?.filter(({ user_id }) => user_id === userId)?.map(({ username }) => username)?.find(username => username), 
                start: moment(startdate).format('YYYY-MM-DD HH:mm'), 
                end: moment(enddate).format('YYYY-MM-DD HH:mm'), 
                destination: destination, 
                distance: distance, 
                mileage: mileage, 
                damage: damage,
                lighting: lighting,
                dashboard: dashboard,
                besides: besides,
                refueling: refueling_amount, 
                parking: parking,
            })
        );

    return (
        <div className="flex justify-end">
            <CSVLink data={data ? data : ''} headers={headers} filename={'회사차량예약정보'}>
                <div className="resManageExcelSet">
                    <button className="resManageExcelButton">
                        <img src={ExcelDown} alt="⇩" className="resManageExcelImage" />
                        <span className="resManageExcelSpan">엑셀다운</span>
                    </button>
                </div>
            </CSVLink>        
        </div>
    )
}

export default Excel;