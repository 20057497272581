import Logo from 'static/image/Logo194.png';
import users from 'static/image/Users.png';
import home from 'static/image/home.png';
import userInfo from 'static/image/userInfo405.png';
import userRes from 'static/image/reservation398.png';
import { useAuth } from 'base/hook/Authcontext';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import PopUp from 'components/main/reservation/PopUp';
import UserModifyButton from './UserModifyButton';
import 'css/TopNav.css'


function TopNav() {
    const [auth, , , logout] = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const topNavUserClick = location?.pathname === '/admin/users/';
    const topNavResClick = location?.pathname === '/admin/reservations/';
    const topNavCarClick = location?.pathname === '/admin/cars/';  
    const [resModalIsOpen, setResModalIsOpen] = useState(false);
    const [modifyModalIsOpen, setModifyModalIsOpen] = useState(false);
    const handleClickResModal = () => { setResModalIsOpen(true) };
    const handleClickModifyModal = () => { setModifyModalIsOpen(true) };

    return (
        <div>
            { auth.isLoggedIn && 
                auth?.authority === 1 ?
                    <div className={`${
                        topNavUserClick
                            ? 'topNavUserClick'
                            : topNavResClick ? 'topNavResClick'
                            : topNavCarClick && 'topNavCarClick'
                        }
                        adminTopNav flex select-none
                    `}>
                        <img src={users} alt="관리자" className={`${
                        topNavUserClick
                            ? 'topNavUserIcon'
                            : topNavResClick ? 'topNavResIcon'
                            : topNavCarClick && 'topNavCarIcon'
                        }
                        adminIcon
                    `} />
                        <p className={`${
                            topNavUserClick
                                ? 'topNavUserHello'
                                : topNavResClick ? 'topNavResHello'
                                : topNavCarClick && 'topNavCarHello'
                            } adminHello`}>{auth?.username}로 로그인 중입니다.</p>

                        <button className={`${
                            topNavUserClick
                                ? 'topNavUserHome'
                                : topNavResClick ? 'topNavResHome'
                                : topNavCarClick && 'topNavCarHome'
                            }
                            adminHome
                        `} onClick={() => navigate(`/`)}>홈으로</button>

                        <button className={`${
                        topNavUserClick
                            ? 'topNavUserLogout'
                            : topNavResClick ? 'topNavResLogout'
                            : topNavCarClick && 'topNavCarLogout'
                        }
                        adminLogoutButton
                    `} onClick={logout}>로그아웃</button>
                    </div>
                    : auth?.authority === 0 ?
                    <div className="topNav select-none">
                        <img src={Logo} alt="CARBBLE" className='userLogo' onClick={() => navigate('/')} />
                        <p className='userAssembble'>ASSEMBBLE CAR MANAGEMENT SYSTEM</p>
                        <img src={users} alt="User" className='userIcon' />
                        <p className='userUser'>USER</p>
                        <p className='userPrivate'>{auth?.username}님 안녕하세요</p>
                        <button className='userLogoutButton' onClick={logout}>로그아웃</button>

                        <div className='userButtons'>
                            <button className='userInfoButton flex' onClick={handleClickModifyModal}>
                                <img src={userInfo} alt="👩🏻‍🤝‍🧑🏼" className='userInfo' />
                                회원정보
                            </button>

                            <button className='userResButton flex' onClick={handleClickResModal}>
                                <img src={userRes} alt="📊" className='userRes' />
                                예약하기
                            </button>
                        </div>
                    </div>
                : ''
                }
                <PopUp resModalIsOpen={resModalIsOpen} setResModalIsOpen={setResModalIsOpen} />
                <UserModifyButton modifyModalIsOpen={modifyModalIsOpen} setModifyModalIsOpen={setModifyModalIsOpen} />
        </div>
    )
}

export default TopNav;