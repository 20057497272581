import { resData } from 'base/redux/reservations/reservationsSlice';
import { useEffect, useState } from 'react';
import { Calendar } from 'react-calendar';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import dayjs from "dayjs";
import { addDays, addYears, addMonths } from 'date-fns';
import { reservationsPutAxios } from 'base/redux/reservations/reservationsPutAxios';
import AdminResData from './AdminResData';
import FullCalendar from '@fullcalendar/react';
import interaction from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { Carousel } from 'react-responsive-carousel';
import 'css/DashBoard.css';
import 'css/ResData.css';
import 'css/FullCalendar.css';
import 'css/FCList.css';
import 'css/Carousel.css';
import { usersData } from 'base/redux/accounts/usersSlice';
import { usersGetAxios } from 'base/redux/accounts/usersGetAxios';
import ResDetailsModal from './ResDetailsModal';
import AdminResButton from 'components/parts/AdminResButton';


function DashCalendar() {
  const [date, setDate] = useState(new Date());
  const resInfo = useSelector(resData);
  const userInfo = useSelector(usersData);
  const dispatch = useDispatch();
  const startYear = addYears(new Date(), -1);
  const endMonth = addMonths(new Date(), 1);
  const currentDay = new Date();  
  const theYear = currentDay.getFullYear();
  const theMonth = currentDay.getMonth();
  const theDate  = currentDay.getDate();
  const theDayOfWeek = currentDay.getDay();
  const [carSelect, setCarSelect] = useState();
  const [returnRes, setReturnRes] = useState(0);
  const [resDetailsOpen, setResDetailsOpen] = useState(false);
  const [selectRes, setSelectRes] = useState();
  const [selectUser, setSelectUser] = useState();
  const [selectCar, setSelectCar] = useState();
  const [adminResModalIsOpen, setAdminResModalIsOpen] = useState(false);

  const openRDModal = () => {
    setResDetailsOpen(true);
  }

  const openResModal = ()=> {
    setAdminResModalIsOpen(true);
  }
  
  const thisWeek = [];
  
    for(var i=0; i<7; i++) {
      let resultDay = new Date(theYear, theMonth, theDate + (i - theDayOfWeek));
      const yyyy = resultDay.getFullYear();
      let mm = Number(resultDay.getMonth()) + 1;
      let dd = resultDay.getDate();
    
      mm = String(mm).length === 1 ? '0' + mm : mm;
      dd = String(dd).length === 1 ? '0' + dd : dd;
    
      thisWeek[i] = yyyy + '-' + mm + '-' + dd;
    }
  
  useEffect(() => {
    dispatch(reservationsPutAxios({ startdate: startYear, enddate: endMonth }));
    dispatch(usersGetAxios());
}, []);

return (
    <div className="DashCalendarBg select-none">
      <div className="DashCalendarBB">
        <span className='DashCalendarSpan1'>+</span>
        <span className="DashCalendarSpan2">PLAN</span>
      </div>
      <div className="DashCalendarBall flex justify-end">
        <div className="DashCalendarRed flex">
          <span className="DashCalendarRedSpan">TUCSON</span>
        </div>
        <div className="DashCalendarGreen flex">
          <span className="DashCalendarGreenSpan">MORNING</span>
        </div>
        <div className='flex'>
          <button className='DashCalendarResButton' onClick={openResModal}> +
            <span className='DashCalendarResSpan'>예약하기</span>
          </button>
        </div>
      </div>

      <hr className='DashCalendarHr' />

      <div>
        <Carousel infiniteLoop={true} showIndicators={false} showStatus={false} showThumbs={false}>
          <div>
            <FullCalendar 
              plugins={[ listPlugin, interaction ]}
              initialView="listMonth"
              viewClassNames="DashCList"
              locale="ko"
              height="auto"
              customButtons={
                  {
                    rental : {
                          text: '대여중',
                          click: function() {
                            setCarSelect('');
                            setReturnRes(0);
                          }
                      },
                      return : {
                        text: '반납완료',
                        click: function() {
                          setCarSelect('');
                          setReturnRes(1);
                        }
                    },
                      tucson: {
                          text: '투싼',
                          click: function() {
                            setCarSelect(1);
                          }
                      },
                      morning: {
                        text: '모닝',
                        click: function() {
                          setCarSelect(2);
                        }
                    },
                  }
              }
              headerToolbar={
                  {
                      start: 'prev,return',
                      center: 'title',
                      end: 'rental,tucson,morning,next'
                  }
              }
              events={
                  resInfo
                  ?.filter(({ rentalState }) => rentalState === returnRes)
                  ?.filter(({ carId }) => !carSelect || carId === carSelect)
                  ?.map(({ reservationId, carId, startdate, enddate, userId, purpose, purposeDetail, destination }) => {
                      return (
                          {
                              title: [`${carId === 1 ? '투싼' : '모닝'} - ${userInfo?.filter(({ user_id }) => user_id === userId)?.map(({ username }) => username)} (${destination ? destination : purposeDetail} ${purpose === 0 ? '출장' : '점검'})`],
                              start: startdate,
                              end: enddate,
                              color: carId === 2 ? "#26c9a8" : "#ff6767",
                              id: reservationId,
                              groupId: userId,
                              constraint: carId,
                          }
                      )
                  })
              }
              eventClick={
                function(info) {
                  openRDModal();
                  setSelectRes(info.event.id);
                  setSelectUser(info.event.groupId);
                  setSelectCar(info.event.constraint);
                }
              }
            />
          </div>

          <div>
            <Calendar                    
              onChange={setDate}
              value={date}
              calendarType="US"
              className="DashCL"
              viewClassNames="DashCLView"
              nextLabel=">"
              next2Label="≫"
              prevLabel="<"
              prev2Label="≪"
              minDetail={"decade"} // 10년단위
              formatShortWeekday={(locale, date) => dayjs(date).format('ddd')}
              formatDay={(locale, date) => dayjs(date).format('DD')} // locale 없으면 => 오늘날짜만 30개
              tileClassName={({ date, view }) => view === 'month' && date.getDay() === 0 && 'DashCLSunday'}
              tileContent={({ date, view }) => {
                let startDateDict = { 1: '', 2: '' };

                return (
                  view === 'month' && // 1달씩 보여줌
                    resInfo?.map(({ reservationId, carId, startdate, enddate }) => {
                      const dateInfo =
                        startDateDict[carId] === moment(enddate).format('YYYY-MM-DD')
                          ? addDays(new Date(enddate), -1)
                          : new Date(enddate);

                        startDateDict[carId] = moment(startdate).format('YYYY-MM-DD');
                            
                          return (
                            date > addDays(new Date(startdate), -1) &&
                            date < dateInfo && 
                            <AdminResData carId={carId} key={reservationId} />
                          );
                    })
                );
              }}
            />
          </div>
        </Carousel>
      </div>
      <ResDetailsModal resDetailsOpen={resDetailsOpen} setResDetailsOpen={setResDetailsOpen} selectRes={selectRes} selectUser={selectUser} selectCar={selectCar} />
      <AdminResButton adminResModalIsOpen={adminResModalIsOpen} setAdminResModalIsOpen={setAdminResModalIsOpen} />
    </div>
  )
}

export default DashCalendar;