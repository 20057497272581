import 'css/ResData.css';


function AdminResData({ carId }) {

    return (
        <div className="relative">
            <div className='flex justify-between'>
                { carId === 2 ?
                    (
                        <div className="AdminResMorningSet flex"> 
                            <p className='AdminResMorningBall'/>
                            <span className='AdminResMorningData'>MOR</span>
                        </div>
                    ) : (
                        <div className="AdminResTucsonSet"> 
                            <p className="AdminResTucsonBall" />
                            <span className='AdminResTucsonData'>TUC</span>
                        </div>
                    )
                }
            </div>
        </div>
    );
}
export default AdminResData;