import { useEffect, useState } from "react";
import { addDays, addMonths, addYears } from 'date-fns';
import { useDispatch, useSelector } from "react-redux";
import { reservationsPutAxios } from "base/redux/reservations/reservationsPutAxios";
import { resData } from "base/redux/reservations/reservationsSlice";
import { usersData } from "base/redux/accounts/usersSlice";
import { usersGetAxios } from "base/redux/accounts/usersGetAxios";
import Calendar from "react-calendar";
import dayjs from "dayjs";
import ResData from "./ResData";
import moment from "moment";
import 'css/Schedule.css';


function Schedule() {
    const [date, setDate] = useState(new Date());
    const startYear = addYears(new Date(), -1);
    const endMonth = addMonths(new Date(), 1);
    const dispatch = useDispatch();
    const resInfo = useSelector(resData);
    
    useEffect(() => {
        dispatch(reservationsPutAxios({ startdate: startYear, enddate: endMonth }))
        dispatch(usersGetAxios())
    }, []);

    return (
        <div className="scheduleBg flex justify-center select-none">
            <div className="scheduleBB flex">
                <span className="scheduleP">+</span>
                <span className="schedulePlan">PLAN</span>
            </div>

            <div className="scheduleBalls flex justify-end">
                <div className="scheduleRed flex">
                    <span className="scheduleRedSpan">TUCSON</span>
                </div>
                <div className="scheduleGreen flex">
                    <span className="scheduleGreenSpan">MORNING</span>
                </div>
            </div>

            <Calendar                    
                onChange={setDate}
                value={date}
                calendarType="US"
                className="ResCL"
                nextLabel=">"
                next2Label="≫"
                prevLabel="<"
                prev2Label="≪"
                minDetail={"decade"} // 10년단위
                formatShortWeekday={(locale, date) => dayjs(date).format('ddd')}
                formatDay={(locale, date) => dayjs(date).format('DD')} // locale 없으면 => 오늘날짜만 30개
                tileClassName={({ date, view }) => view === 'month' && date.getDay() === 0 && 'sunday'}
                tileContent={({ date, view }) => {
                    let startDateDict = { 1: '', 2: '' };

                    return (
                        view === 'month' && // 1달씩 보여줌
                        resInfo?.map(({ reservationId, carId, startdate, enddate }) => {
                            const dateInfo =
                            startDateDict[carId] === moment(enddate).format('YYYY-MM-DD')
                                ? addDays(new Date(enddate), -1)
                                : new Date(enddate);

                                startDateDict[carId] = moment(startdate).format('YYYY-MM-DD');
                        
                        return (
                            date > addDays(new Date(startdate), -1) &&
                            date < dateInfo && 
                            <ResData carId={carId} key={reservationId} />
                                );
                            })
                        );
                    }}
                />
        </div>
    )
}

export default Schedule;