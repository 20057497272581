import 'css/DashBoard.css'
import DashBoardT from 'static/image/DashT494.png'
import DashBoardM from 'static/image/DashM491.png';
import { useEffect, useState } from 'react';
import axios from 'axios';


function DashCar() {
  const [cars, setCars] = useState();
  const TucsonMileage = cars?.filter(({ carId }) => carId === 1)?.map(({ carMileage }) => carMileage);
  const MorningMileage = cars?.filter(({ carId }) => carId === 2)?.map(({ carMileage }) => carMileage);

  useEffect(() => {
    axios({
        url: '/api/cars',
        method: 'get',
    })
    .then(function(response) {
        setCars(response.data);
    })
    .catch(function(error) {
        console.log(error);
    })
}, []);

return(
    <>
      <div className="DashCarBgT">
        <div className="DashCarTBB select-none">
          <span className='DashCarTSpan1'>+</span>
          <span className="DashCarTSpan2">TUCSON</span>
        </div>
        <img src={DashBoardT} alt="🚙" className='DashCarTImage select-none' />
        <div className='DashCarTwSet'>
          <h1 className='DashCarTH1'>{TucsonMileage ? TucsonMileage.toLocaleString() : ''}</h1>
          <span className='DashCarTSpan'>Km</span>
        </div>
      </div>
      <div className="DashCarBgM">
        <div className="DashCarMBB select-none">
          <span className='DashCarMSpan1'>+</span>
          <span className="DashCarMSpan2">MORNING</span>
        </div>
        <img src={DashBoardM} alt="🚗" className='DashCarMImage select-none' />
        <div className='DashCarMwSet'>
          <h1 className='DashCarMH1'>{MorningMileage ? MorningMileage.toLocaleString() : ''}</h1>
          <span className='DashCarMSpan'>Km</span>
        </div>
      </div>
    </>
  )
}

export default DashCar;