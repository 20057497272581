import axios from "axios";
import { usersGetAxios } from "base/redux/accounts/usersGetAxios";
import 'css/UserManagement.css';
import { useDispatch } from "react-redux";
import UserDelete from 'static/image/Cancel708.png';


function UserDeleteButton({ user_id, username }) {
    const dispatch = useDispatch();

    const handleUserDeleteButton = (e) => {
        e.preventDefault();

        window.confirm(`${username} 회원을 삭제하시겠습니까?`) &&
            axios({
                url: `/api/users/${user_id}`,
                method: 'delete',
                data: user_id,
            })
            .then(function(response) {
                dispatch(usersGetAxios());
            })
            .catch(function(error) {
                console.log(error);
            })
        };
    
    return (
        <div>
            <button className='userDeleteButton' onClick={handleUserDeleteButton}>
                <img src={UserDelete} alt="⊗" className='userDeleteIcon' />
                <span className='userDeleteSpan'>삭 제</span>
            </button>
        </div>
    )
}

export default UserDeleteButton;