import RecentRes from "components/main/reservation/RecentRes";
import ResList from "components/main/reservation/ResList";
import Schedule from "components/main/reservation/Schedule";
import 'css/TopNav.css'
// import Test from "Test";


function MainPage() {
    return (
        <div>
            <div className="mainBg w-screen h-screen">
                <Schedule />
                <RecentRes />
                <ResList />
            </div>
        </div>
        // <Test />
    )
}

export default MainPage;