import photo from 'static/image/404 notfound.jpg'


function NotFound() {
    return (
        <div>
            <div>
                <div className="flex justify-center">
                    <img className="w-1/4 h-1/4" src={photo} alter="404 Not Found"/>
                </div>
            </div>
        </div>
    );
}

export default NotFound;