import { resData } from 'base/redux/reservations/reservationsSlice';
import 'css/ResList.css';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ResIcon from 'static/image/ResListResIcon725.png';
import ResReturn from 'static/image/Return727.png';
import ResCancel from 'static/image/Cancel708.png';
import PopUp from './PopUp';
import ResReturnButton from './ResReturnButton';
import { useAuth } from 'base/hook/Authcontext';
import { usersData } from 'base/redux/accounts/usersSlice';
import { reservationsDeleteAxios } from 'base/redux/reservations/reservationDeleteAxios';
import Search from 'static/image/Search608.png';


function ResList() {
  const [auth] = useAuth();
  const resInfo = useSelector(resData);
  const userInfo = useSelector(usersData);
  const dispatch = useDispatch();
  const formatDateTime = (inputDate, formatString = 'YYYY-MM-DD HH:mm') => {return moment(inputDate).format(formatString)};
  const [resModalIsOpen, setResModalIsOpen] = useState(false);
  const [returnModalIsOpen, setReturnModalIsOpen] = useState(false);
  const [selectCarId, setSelectCarId] = useState();
  const [selectResId, setSelectResId] = useState();
  const [optionCarId, setOptionCarId] = useState();
  // const userRecentResId = resInfo?.filter(({ userId }) => userId === auth?.user_id)?.map(({ reservationId }) => reservationId);
  // const userRecentResCar = resInfo?.filter(({ userId }) => userId === auth?.user_id)?.map(({ carId }) => carId);
  const handleClickResModal = () => { setResModalIsOpen(true) };

  // const handleClickResDetailModal = (e) => { 
  //   e.preventDefault();

  //   resInfo
  //       ?.filter(({ startdate }) => moment(startdate)?.format('YYYY-MM-DD HH:mm') > moment(new Date())?.format('YYYY-MM-DD HH:mm'))
  //       ?.filter(({ reservationId, carId }) => reservationId < Math.max(...userRecentResId) && carId === parseInt(userRecentResCar))
  //       ?.map(({ startdate, carId, parking }) => 
  //         alert(`${moment(startdate)?.format('YYYY-MM-DD')}일 예약한 ${carId === 1 ? '투싼' : '모닝'} 차량의 주차 위치는 ${parking}입니다.`)
  //     )
  // };
  
  const handleClickReturnModal = (e) => {
    setSelectCarId(e.currentTarget.value);
    setSelectResId(e.currentTarget.name);
    setReturnModalIsOpen(true);
  };
  
  const optionClick = (e) => {
    setOptionCarId(parseInt(e.target.value));
  }

  const onDeleteClick = (e) => {
    dispatch(reservationsDeleteAxios({ reservationId: e.currentTarget.name }));
  }

  return (
    <div className="ResListBg">
      <div className='flex justify-between'>
        <div className="ResListBB">
          <span className='ResListP'>+</span>
          <span className="ResListSpan">예약현황</span>
        </div>
        {/* <button className="ResListDetailsButton select-none" onClick={handleClickResDetailModal}> 주차위치확인
          <img src={Search} alt="+" className="ResListDetailsImage" />
        </button> */}
        <button className="ResListResButton" onClick={handleClickResModal}>예 약
          <img src={ResIcon} alt="📝" className='ResListResIcon' />
        </button>
      </div>

      <div className="ResList">
        <table className="ResListTable">
          <thead className="ResListThead">
            <tr className='ResListTr'>
              <th scope="col" className="px-8 py-3">
                <select name="carId" id="carId" className='ResListSelect outline-none' onChange={optionClick}>
                  <option value="">차종</option>
                  <option value="2">모닝</option>
                  <option value="1">투싼</option>
                </select>
              </th>
              <th scope="col" className="px-8 py-3">
                <span className='relative left-2'>운전자</span>
              </th>
              <th scope="col" className="px-16 py-3">
                <span className='relative left-2'>시작일</span>
              </th>
              <th scope="col" className="px-24 py-3">
                <span className='relative left-4'>종료일</span>
              </th>
              <th scope="col" className="px-7 py-3">
                <span className='relative left-6'>목적</span>
              </th>
              <th scope="col" className="px-[238px] py-3">
                <span className='relative left-3'>상세목적</span>
              </th>
              <th scope="col" className="px-11 py-3">
                <span className='relative'>반납 / 취소</span>
              </th>
            </tr>
          </thead>
      
          { resInfo
            ?.filter(({ carId }) => parseInt(optionCarId) ? carId === parseInt(optionCarId) : true)
            ?.filter(({ rentalState }) => rentalState === 0) 
            // 필터 이중으로 쓸 수 있음. 차량관련 필터와 렌탈스테이트 관련 필터를 한번에 쓰면 차량선택시 렌탈스테이트 필터가 먹히지 않음. 나눠야한다.
            ?.map(
            ({ reservationId, carId, userId, startdate, enddate, purpose, purposeDetail, rentalState }) => 
              <>
                <tbody key={reservationId} className="ResListTbody">
                  <tr className="ResListTbodyTr">
                    <th scope="row" className="px-4 py-4">
                      { parseInt(optionCarId) === 2 
                        ?
                          <span className='relative left-7'>{carId === 1 ? '투싼' :  '모닝' }</span>
                        :
                          <span className='relative left-8'>{carId === 1 ? '투싼' :  '모닝' }</span>
                      }
                    </th>
                    <td className='px-4 py-3'>
                      { parseInt(optionCarId) === 2 
                        ? <span className='relative left-[42px]'>{userInfo?.filter(({ user_id }) => userId === user_id)?.map(({ username }) => username)}</span>
                        : <span className='relative left-[55px]'>{userInfo?.filter(({ user_id }) => userId === user_id)?.map(({ username }) => username)}</span>
                      }
                    </td>
                    <td className='px-4 py-3'>
                    { parseInt(optionCarId) === 2 
                      ? <span className="relative right-2">{formatDateTime(startdate)}</span>
                      : <span className="relative left-4">{formatDateTime(startdate)}</span>
                    }
                    </td>
                    <td className='px-4 py-3'>
                    { parseInt(optionCarId) === 2 
                      ? <span className="relative right-16">{formatDateTime(enddate)}</span>
                      : <span className="relative right-5">{formatDateTime(enddate)}</span>
                    }
                    </td>
                    <td className="px-5 py-3">
                    { parseInt(optionCarId) === 2 
                      ? <span className='relative right-[90px]'>{purpose === 0 ? '출장' : '정비'}</span>
                      : <span className='relative right-6'>{purpose === 0 ? '출장' : '정비'}</span>
                    }
                    </td>
                    <td className="px-4 py-3">
                    { parseInt(optionCarId) === 2 
                      ? <span className='relative left-[58px] break-all'>{purposeDetail}</span>
                      : <span className='relative left-[70px] break-all'>{purposeDetail}</span>
                    }
                    </td>
                    { auth?.user_id === userId ? ( 
                      <>
                        { rentalState === 1 ?
                          <td className="px-6 py-3">
                            { parseInt(optionCarId) === 2
                              ?
                                <button className='ResReturnButton2 invisible' onClick={handleClickReturnModal} name={reservationId} value={carId}>
                                  <img src={ResReturn} alt="↺" className='ResReturnIcon' />
                                  <span className='ResReturnSpan'>반납</span>
                                </button>
                              :
                                <button className='ResReturnButton invisible' onClick={handleClickReturnModal} name={reservationId} value={carId}>
                                  <img src={ResReturn} alt="↺" className='ResReturnIcon' />
                                  <span className='ResReturnSpan'>반납</span>
                                </button>
                            }
                          </td>
                          :
                          <td className="px-6 py-3">
                            { parseInt(optionCarId) === 2
                              ?
                                <button className='ResReturnButton2' onClick={handleClickReturnModal} name={reservationId} value={carId}>
                                  <img src={ResReturn} alt="↺" className='ResReturnIcon' />
                                  <span className='ResReturnSpan'>반납</span>
                                </button>
                              :
                                <button className='ResReturnButton' onClick={handleClickReturnModal} name={reservationId} value={carId}>
                                  <img src={ResReturn} alt="↺" className='ResReturnIcon' />
                                  <span className='ResReturnSpan'>반납</span>
                                </button>
                            }
                          </td>
                        }
                        { startdate > moment(new Date()).format('YYYY-MM-DD HH:mm') && rentalState === 0 ? 
                          <td className="px-6 py-3">
                            { parseInt(optionCarId) === 2
                              ?
                                <button className='ResCancelButton2' onClick={onDeleteClick} name={reservationId}>
                                  <img src={ResCancel} alt="⊗" className='ResCancelIcon' />
                                  <span className='ResCancelSpan'>취소</span>
                                </button>
                              :
                                <button className='ResCancelButton' onClick={onDeleteClick} name={reservationId}>
                                  <img src={ResCancel} alt="⊗" className='ResCancelIcon' />
                                  <span className='ResCancelSpan'>취소</span>
                                </button>
                            }
                          </td>
                          : 
                          <td className="px-6 py-3">
                            { parseInt(optionCarId) === 2
                              ?
                                <button className='ResCancelButton2 invisible' onClick={onDeleteClick} name={reservationId}>
                                  <img src={ResCancel} alt="⊗" className='ResCancelIcon' />
                                  <span className='ResCancelSpan'>취소</span>
                                </button>
                              :
                                <button className='ResCancelButton invisible' onClick={onDeleteClick} name={reservationId}>
                                  <img src={ResCancel} alt="⊗" className='ResCancelIcon' />
                                  <span className='ResCancelSpan'>취소</span>
                                </button>
                            }
                          </td>
                        }
                      </> // 내 예약 건에 대해 보이는 버튼 처리
                    ) : (
                      <>
                        <td className="px-6 py-3">
                          { parseInt(optionCarId) === 2
                              ?
                                <button className='ResReturnButton2 invisible' onClick={handleClickReturnModal} name={reservationId} value={carId}>
                                  <img src={ResReturn} alt="↺" className='ResReturnIcon' />
                                  <span className='ResReturnSpan'>반납</span>
                                </button>
                              :
                                <button className='ResReturnButton invisible' onClick={handleClickReturnModal} name={reservationId} value={carId}>
                                  <img src={ResReturn} alt="↺" className='ResReturnIcon' />
                                  <span className='ResReturnSpan'>반납</span>
                                </button>
                            }
                        </td>
                        <td className="px-6 py-3">
                          { parseInt(optionCarId) === 2
                              ?
                                <button className='ResCancelButton2 invisible' onClick={onDeleteClick} value={reservationId}>
                                  <img src={ResCancel} alt="⊗" className='ResCancelIcon' />
                                  <span className='ResCancelSpan'>취소</span>
                                </button>
                              :
                                <button className='ResCancelButton invisible' onClick={onDeleteClick} value={reservationId}>
                                  <img src={ResCancel} alt="⊗" className='ResCancelIcon' />
                                  <span className='ResCancelSpan'>취소</span>
                                </button>
                            }
                        </td>
                    </> // 내 예약 건 외에 인비저블처리한 버튼
                    )}
                  </tr>
                </tbody>
                <PopUp resModalIsOpen={resModalIsOpen} setResModalIsOpen={setResModalIsOpen} />
                <ResReturnButton returnModalIsOpen={returnModalIsOpen} setReturnModalIsOpen={setReturnModalIsOpen} selectResId={selectResId} selectCarId={selectCarId} />
              </>
            )}
        </table>
      </div>
    </div>
  );
};

export default ResList;