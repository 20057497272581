import axios from 'axios';
import 'css/DashBoard.css'
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useState } from 'react';
import { useEffect } from 'react';


function DashPlan() {
  const years = new Date().getFullYear();
  const monthly = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [monthTSum, setMonthTSum] = useState([]); // 중괄호 없으면 리듀스 오류남
  const [monthMSum, setMonthMSum] = useState([]); // 중괄호 없으면 리듀스 오류남
  const [refuelingAmountData, setRefuelingAmountData] = useState();
  const refuelingTInfo = monthTSum.reduce((a,v) =>  a = a + v.sum , 0); // 투싼 주유비용 합계
  const refuelingMInfo = monthMSum.reduce((a,v) =>  a = a + v.sum , 0); // 모닝 주유비용 합계

  useEffect(() => {
    setMonthTSum([]);
    setMonthMSum([]);

    monthly?.map((value) => {
      const monthTInfo = refuelingAmountData?.filter(({ car_id }) => car_id === 1)
      ?.find(({ month }) => parseInt(month?.slice(5)) === value);
      const monthMInfo = refuelingAmountData?.filter(({ car_id }) => car_id === 2)
      ?.find(({ month }) => parseInt(month?.slice(5)) === value);   

      setMonthTSum((prev) => [
        ...prev,
        {
          month: value,
          sum: monthTInfo ? monthTInfo.sum : 0
        }
      ]);
      setMonthMSum((prev) => [
        ...prev,
        {
          month: value,
          sum: monthMInfo ? monthMInfo.sum : 0
        }
      ]);
    });
  }, [refuelingAmountData]);

  const initialOptions = {
    title : { 
      text : "" 
    },
    chart : { 
      type : "column",
      style: {
        color: '#707070',
        fontFamily: 'S-CoreDream-5',
        fontWeight: 'normal'
      }
    },
    legend: {
      floating: false,
      align: 'center',
      verticalAlign: 'bottom',
      symbolRadius:20,//범례 심볼 radius 지정
        symbolWidth:15,
        symbolHeight:10,
        itemDistance:150,//범례 간 간격 지정.
        itemStyle: {
          color:'#8e8e8f',
          fontSize: '12px',
          fontWeight:'normal'
        },
      x: 10,//가로 위치 지정.
      y: -3,//세로 위치 지정.
    },
    xAxis: {
      categories: monthly,
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: '(천원)',
        align: 'high',
      },
    },
    credits: {
      enabled: false
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}월</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:f} ₩</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        pointPadding: 0.1,
        borderWidth: 0
      }
    },
    series: [{
      name: 'MORNING',
      data: monthMSum?.map(({ sum }) => sum),
      color: '#fea031',
    }, {
      name: 'TUCSON',
      data: monthTSum?.map(({ sum }) => sum),
      color: '#69a6f7',
    },]
  }; 
  
  useEffect(() => {
    axios({
        url: `/api/graph/SumRefuelingAmount`,
        method: 'put',
        data: {
          startdate: years + '-01-01',
          enddate: years + '-12-31'
        }    
    })
    .then(function(response) {
      setRefuelingAmountData(response.data);
    })
    .catch(function(error) {
        console.log(error);
    })
  }, []);

  return (
    <div className="DashPlanBg">
      <div className="DashPlanBB select-none">
        <span className='DashPlanSpan1'>+</span>
        <span className="DashPlanSpan2">MONTH PLAN</span>
      </div>

      <div className='DashPlanGB select-none'>
        <span className="DashPlanSpan3">주유비용</span>
      </div>

      <div className='DashPlanCarSet flex justify-end select-none'>
        <div className='DashPlanMorning'>
          <span className='DashPlanMorningSpan'>MORNING</span>
        </div>
        <div className='DashPlanTucson'>
          <span className='DashPlanTucsonSpan'>TUCSON</span>
        </div>
      </div>

      <div className='absolute'>
        <HighchartsReact 
          highcharts={Highcharts}
          options={initialOptions}
          containerProps={{ className: "DashPlanMonthChart" }}
        />
        <div className='DashPlanMoneySet flex'>
          <h1 className='DashPlanMorningH1 flex'>{refuelingMInfo?.toLocaleString()}<span className='DashPlanMorningSpan2'>원</span></h1>
          <div className='DashPlanLine flex'></div>
          <h1 className='DashPlanTucsonH1 flex'>{refuelingTInfo?.toLocaleString()}<span className='DashPlanTucsonSpan2'>원</span></h1>`
        </div>
      </div>
    </div>
  )
}

export default DashPlan;