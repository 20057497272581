import { useEffect, useState } from "react";
import 'css/UserManagement.css';
import UserAddButton from "components/parts/UserAddButton";
import DashSelect from "./DashSelect";
import DashRefuel from "./DashRefuel";
import UserModify from 'static/image/ResListResIcon725.png';
import Search from 'static/image/Search608.png';
import userAdd from 'static/image/UserAdd580.png';
import UserDeleteButton from "components/parts/UserDeleteButton";
import UserModifyButton from "components/parts/UserModifyButton";
import { useDispatch, useSelector } from "react-redux";
import { usersData } from "base/redux/accounts/usersSlice";
import { usersGetAxios } from "base/redux/accounts/usersGetAxios";
import axios from "axios";
import useFieldValues from "base/hook/useFieldValues";
import { computeSegDraggable } from "@fullcalendar/react";


const INIT_FILED_VALUES = {
    part_name: '',
    user_name: '',
}


function UserManagement() {
    const dispatch = useDispatch();
    const userInfo = useSelector(usersData);
    const [selectUser, setSelectUser] = useState();
    const [userAddModalIsOpen, setUserAddModalIsOpen] = useState(false);
    const [userModifyModalIsOpen, setUserModifyModalIsOpen] = useState(false);
    const selectedUserInfo = userInfo?.find(({ user_id }) => user_id === (selectUser ? selectUser : 201200000));
    const [selectSearch, setSelectSearch] = useState();
    const [searchInfo, setSearchInfo] = useState();
    
    const { 
        fieldValues, 
        handleFieldChange, 
        emptyFieldValues,
    } = useFieldValues(INIT_FILED_VALUES);

    const handleClickModify = (e) => { 
        setSelectUser(parseInt(e.currentTarget.value)); 
        setUserModifyModalIsOpen(true) 
    };
    
    const userAddButton = () => { setUserAddModalIsOpen(true); }

    useEffect(() => {
        dispatch(usersGetAxios());
    }, []);

    const handleSearchSelect = (e) => {
        setSelectSearch(e.target.value);
        emptyFieldValues();
    }

    const handleSearchButton = async (e) => {
        e.preventDefault();

            await axios({
                url: `/api/users/search?${selectSearch === 'part_name' ? 'part_name=' + fieldValues.part_name : 'user_name=' + fieldValues.user_name}`,
                method: 'put',
                data: { ...fieldValues, part_name: fieldValues.part_name, user_name: fieldValues.user_name }
            })
            .then(function(response) {
                setSearchInfo(response?.data);
            })
            .catch(function(error) {
                console.log(error);
            })
        };

    const handleEnterSearch = (e) => {
        if (e.key === 'Enter') {
            handleSearchButton(e);
        }
    };
        
    return ( 
        <div className="userBaseBg w-screen h-screen">
            <DashSelect />
            <DashRefuel />
            <UserAddButton />
            <div className="userManageBg">
                <div className="userManageBB select-none">
                    <span className='userManageSpan1'>+</span>
                    <span className="userManageSpan2">회원관리</span>
                </div>

                <div>
                    <button className="userManageAddButton select-none" onClick={userAddButton}>
                        <img src={userAdd} alt="+" className="userManageAddImage" />
                    </button>
                </div>

                <div className="userManagementSearchSet flex">
                    <form onSubmit={handleSearchButton} onKeyPress={handleEnterSearch}>
                        <select name="search" id="search" defaultValue="user_name" onChange={handleSearchSelect} className="userManagementSearchSelect">
                            <option value="part_name" className="userManagementSearchSelectOption">소속</option>
                            <option value="user_name" className="userManagementSearchSelectOption">회원명</option>
                        </select>
                        <input 
                            type='text'
                            name={selectSearch === 'part_name' ? 'part_name' : 'user_name'}
                            value={selectSearch === 'part_name' ? fieldValues.part_name : fieldValues.user_name} 
                            onChange={handleFieldChange} 
                            className="userManagementSearchInput" 
                        />
                        <button className="userManagementSearchButton">
                            <img src={Search} alt="🔍" className="userManagementSearchImg" />
                            <span className="userManagementSearchSpan">검 색</span>
                        </button>
                    </form>
                </div>

                <div className="userManageList">
                    <table className="userManageTable">
                        <thead className="userManageThead">
                            <tr>
                                <th scope="col" className="px-14 py-3">
                                    회원ID
                                </th>
                                <th scope="col" className="px-10 py-3">
                                    회원명
                                </th>
                                <th scope="col" className="px-24 py-3">
                                    소속팀
                                </th>
                                <th scope="col" className="px-14 py-3">
                                    직위
                                </th>
                                <th scope="col" className="px-20 py-3">
                                    권한
                                </th>
                                <th scope="col" className="px-10  py-3">
                                    전화번호
                                </th>
                                <th scope="col" className="px-36 py-3 select-none">
                                    <span className="relative left-12">회원정보수정/삭제</span>
                                </th>
                            </tr>
                        </thead>
                    
                        { !searchInfo ? 
                            userInfo
                                ?.map(
                                ({ username, password, partname, position, user_id, telephone, authority }) => 
                                <tbody key={user_id}>
                                    <tr className="userManageTbody">
                                        <th scope="row" className="userManageTbodyTh">
                                            {user_id}
                                        </th>
                                        <td className="px-7 py-4">
                                            {username}
                                        </td>
                                        <td className="px-4 py-4">
                                            <span className="relative left-5">{partname}</span>
                                        </td>
                                        <td className="px-8 py-4">
                                            <span className="relative left-4">{position}</span>
                                        </td>
                                        <td className="px-6 py-4">
                                            <span className="relative left-2">{authority === 0 ? '일반회원' : '관리자'}</span>
                                        </td>
                                        <td className="px-6 py-4">
                                            {authority === 0 
                                                ? `${telephone?.slice(0, 3)}-${telephone?.slice(3, 7)}-${telephone?.slice(7, 11)}`
                                                : `${telephone?.slice(0, 3)}-${telephone?.slice(3, 6)}-${telephone?.slice(6, 10)}`
                                            }
                                        </td>
                                        <td className="px-6 py-3 select-none">
                                            <button className='userModifyButton' onClick={handleClickModify} value={user_id} >
                                                <img src={UserModify} alt="↺" className='userModifyIcon' />
                                                <span className='userModifySpan'>수 정</span>
                                            </button>
                                        </td>
                                        <td className="px-6 py-3 select-none">
                                            <UserDeleteButton user_id={user_id} username={username} />
                                        </td>
                                    </tr>
                                </tbody>
                                ) :
                            searchInfo
                                ?.map(
                                ({ username, password, partname, position, user_id, telephone, authority }) => 
                                <tbody key={user_id}>
                                    <tr className="userManageTbody">
                                        <th scope="row" className="userManageTbodyTh">
                                            {user_id}
                                        </th>
                                        <td className="px-7 py-4">
                                            {username}
                                        </td>
                                        <td className="px-4 py-4">
                                            <span className="relative left-5">{partname}</span>
                                        </td>
                                        <td className="px-8 py-4">
                                            <span className="relative left-4">{position}</span>
                                        </td>
                                        <td className="px-6 py-4">
                                            <span className="relative left-2">{authority === 0 ? '일반회원' : '관리자'}</span>
                                        </td>
                                        <td className="px-6 py-4">
                                            {authority === 0 
                                                ? `${telephone?.slice(0, 3)}-${telephone?.slice(3, 7)}-${telephone?.slice(7, 11)}`
                                                : `${telephone?.slice(0, 3)}-${telephone?.slice(3, 6)}-${telephone?.slice(6, 10)}`
                                            }
                                        </td>
                                        <td className="px-6 py-3 select-none">
                                            <button className='userModifyButton' onClick={handleClickModify} value={user_id} >
                                                <img src={UserModify} alt="↺" className='userModifyIcon' />
                                                <span className='userModifySpan'>수 정</span>
                                            </button>
                                        </td>
                                        <td className="px-6 py-3 select-none">
                                            <UserDeleteButton user_id={user_id} username={username} />
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                    </table>
                </div>
                <UserAddButton userAddModalIsOpen={userAddModalIsOpen} setUserAddModalIsOpen={setUserAddModalIsOpen} />
                <UserModifyButton setUserModifyModalIsOpen={setUserModifyModalIsOpen} userModifyModalIsOpen={userModifyModalIsOpen} selectedUserInfo={selectedUserInfo} />
            </div>
        </div>
    )
}

export default UserManagement;