import { createSlice } from '@reduxjs/toolkit'


export const checkSlice = createSlice({
    name: 'check',
    initialState: {
        checkData: [],
    },
    reducers: {
        putCheck: (state, action) => {
            return {...state, checkData: [...action.payload]}
        },
        addCheck: (state, action) => {
            state.checkData = action.payload
        },
    },
})

export const { putCheck, addCheck } = checkSlice.actions;
export const checkData = state => state?.check?.checkData;
export default checkSlice.reducer;