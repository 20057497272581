import moment from "moment";
import DialogBox from 'react-modeless';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resData } from 'base/redux/reservations/reservationsSlice';
import { usersData } from 'base/redux/accounts/usersSlice';
import { reservationsPutAxios } from 'base/redux/reservations/reservationsPutAxios';
import { usersGetAxios } from 'base/redux/accounts/usersGetAxios';
import ResDetailsCarImg from 'static/image/DashT494.png';
import BlueCalendar from 'static/image/Calendar650.png';
import BlueCar from 'static/image/BlueCar652.png';
import MorningImg from 'static/image/Morning689.png';
import TucsonImg from 'static/image/Tucson690.png';
import ResCancel from 'static/image/Cancel708.png';
import ResReturn from 'static/image/Return727.png';
import 'css/ResDetailModal.css';
import { reservationsDeleteAxios } from "base/redux/reservations/reservationDeleteAxios";
import AdminResReturn from "./AdminResReturn";


function ResDetailsModal({ resDetailsOpen, setResDetailsOpen, selectRes, selectUser, selectCar }) {
  const resInfo = useSelector(resData);
  const userInfo = useSelector(usersData);
  const dispatch = useDispatch();
  const years = new Date().getFullYear();
  const month = new Date().getMonth() + 1 >= 10 ? new Date().getMonth() + 1 : '0' + (new Date().getMonth() + 1);
  const monthly = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const maxResId = resInfo?.filter(({ userId }) => userId === parseInt(selectUser))?.filter(({ rentalState }) => rentalState === 1)?.map(({ reservationId }) => reservationId);
  const allDistance = resInfo?.filter(({ userId }) => userId === parseInt(selectUser))?.filter(({ rentalState }) => rentalState === 1)?.map(({ distance }) => distance);
  const resNumberPerMonth = resInfo?.filter(({ userId }) => userId === parseInt(selectUser))?.filter(({ rentalState, startdate }) => rentalState === 1 && startdate?.slice(0, 7) === String(years + '-' + month))?.map(({ reservationId }) => reservationId).length;
  const lastResDate = resInfo?.filter(({ userId }) => userId === parseInt(selectUser))?.filter(({ rentalState, reservationId }) => rentalState === 1 && reservationId === Math.max(...maxResId))?.map(({ startdate }) => startdate ? moment(startdate).format('YYYY-MM-DD') : '');
  const [monthTSum, setMonthTSum] = useState([]); // 중괄호 없으면 리듀스 오류남
  const [monthMSum, setMonthMSum] = useState([]); // 중괄호 없으면 리듀스 오류남
  const rentalTInfo = monthTSum.reduce((a,v) =>  a = a + v.rentalNum , 0); // 투싼 주유비용 합계
  const rentalMInfo = monthMSum.reduce((a,v) =>  a = a + v.rentalNum , 0); // 모닝 주유비용 합계
  const sumAllDistance = allDistance.reduce((a, v) => a = a + v , 0);
  const [adminResReturnOpen, setAdminResReturnOpen] = useState(false);

  const truncatePurposeDetail = (str) => {
    if (str.length > 10) {
      return str.slice(0, 10) + '...';
    } else {
      return str;
    }
  }

  const onDeleteClick = () => {
    dispatch(reservationsDeleteAxios({ reservationId: selectRes }));
  }

  const handleClickReturnModal = () => {
    setAdminResReturnOpen(true);
    setResDetailsOpen(false);
  };

  useEffect(() => {
    setMonthTSum([]);
    setMonthMSum([]);

    monthly?.map((value) => {
      const monthTInfo = resInfo
        ?.filter(({ userId, rentalState }) => userId === parseInt(selectUser) && rentalState === 1)
        ?.filter(({ carId }) => carId === 1)
        ?.filter(({ startdate }) => parseInt(startdate.slice(5)) === value)
        ?.map(({ reservationId }) => reservationId);
      
      const monthMInfo = resInfo
        ?.filter(({ userId, rentalState }) => userId === parseInt(selectUser) && rentalState === 1)
        ?.filter(({ carId }) => carId === 2)
        ?.filter(({ startdate }) => parseInt(startdate.slice(5)) === value)
        ?.map(({ reservationId }) => reservationId);

      setMonthTSum((prev) => [
        ...prev,
        {
          month: value,
          rentalNum: monthTInfo ? monthTInfo.length : 0
        }
      ]);
      setMonthMSum((prev) => [
        ...prev,
        {
          month: value,
          rentalNum: monthMInfo ? monthMInfo.length : 0
        }
      ]);
    });
  }, [resInfo, resDetailsOpen]);

  const initialOptions = {
    title : { 
      text : "" 
    },
    chart : { 
      type : "column",
      style: {
        color: '#707070',
        fontFamily: 'S-CoreDream-5',
        fontWeight: 'normal'
      }
    },
    legend: '',
    xAxis: {
      categories: monthly,
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: '(회)',
        align: 'high',
      },
    },
    credits: {
      enabled: false
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}월</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:f} 회</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        pointPadding: 0.1,
        borderWidth: 0
      }
    },
    series: [{
      name: 'MORNING',
      data: monthMSum?.map(({ rentalNum }) => rentalNum),
      color: '#fea031',
    }, {
      name: 'TUCSON',
      data: monthTSum?.map(({ rentalNum }) => rentalNum),
      color: '#69a6f7',
    },]
  }; 
  
  useEffect(() => {
    dispatch(reservationsPutAxios({ startdate: years + '-01-01', enddate: years + '-12-31' }));
    dispatch(usersGetAxios());
}, []);

console.log(resInfo)

return(
    <div>
      <DialogBox            
                ariaHideApp={false}
                isOpen={resDetailsOpen}
                onRequestClose={() => setResDetailsOpen(false)}
                shouldCloseOnOverlayClick={false}
                className="userAddModal shadow-md outline-none"
                style={{
                    backgroundColor: 'rgba(58, 61, 63, 0.8)',
                    width: '100%',
                    height: '100vh',
                }}
            >
              { resInfo
                ?.filter(({ userId }) => userId === parseInt(selectUser))
                ?.filter(({ reservationId }) => reservationId === parseInt(selectRes))
                ?.map(({ userId, startdate, enddate, destination, purposeDetail, parking, distance, rentalState, returnDate, reservationId }) => 

                  <form className="ResDetailsModal" key={reservationId}>
                    <div className="ResDetailsModalBg">
                        <div className="relative top-[40px] left-[1075px]">
                            <button className="ResDetailsXButton" onClick={() => setResDetailsOpen(false)}>
                                <span className="ResDetailsXButtonSpan">X</span>
                            </button>
                        </div>
                        
                        <div className="ResDetailsBB">
                            <span className='ResDetailsSpan1'>+</span>
                            <span className="ResDetailsSpan2">대여내역 자세히보기</span>
                            { rentalState === 0 ? 
                                ( moment(startdate).format('YYYY-MM-DD HH:mm') > moment(new Date()).format('YYYY-MM-DD HH:mm') ?
                                  <button className='ResDetailsCancelButton' onClick={onDeleteClick}>
                                    <img src={ResCancel} alt="⊗" className='ResDetailsCancelIcon' />
                                    <span className='ResDetailsCancelSpan'>예약취소</span>
                                  </button> 
                                : 
                                  <button className='ResDetailsReturnButton' onClick={handleClickReturnModal}>
                                    <img src={ResReturn} alt="↺" className='ResDetailsReturnIcon' />
                                    <span className='ResDetailsReturnSpan'>반납하기</span>
                                  </button>
                                )
                            : 
                                ( moment(startdate).format('YYYY-MM-DD HH:mm') > moment(new Date()).format('YYYY-MM-DD HH:mm') ?
                                  <button className='ResDetailsCancelButton invisible' onClick={onDeleteClick}>
                                    <img src={ResCancel} alt="⊗" className='ResDetailsCancelIcon' />
                                    <span className='ResDetailsCancelSpan'>예약취소</span>
                                  </button> 
                                : 
                                  <button className='ResDetailsReturnButton invisible' onClick={handleClickReturnModal}>
                                    <img src={ResReturn} alt="↺" className='ResDetailsReturnIcon' />
                                    <span className='ResDetailsReturnSpan'>반납하기</span>
                                  </button>
                                )
                            }
                        </div>

                        <div className='ResDetailsBg'>
                          <div className='ResDetailCarSet'>
                            <img src={ResDetailsCarImg} alt="🚘" className='ResDetailsCarImg' />
                            { distance > 1000 
                                ? <span className='ResDetailsDistance1'>{rentalState === 1 ? distance.toLocaleString() : ''}
                                    <span className='ResDetailsKm'>{rentalState === 1 ? 'Km' : ''}</span>
                                  </span>
                                : <span className='ResDetailsDistance2'>{rentalState === 1 ? distance.toLocaleString() : ''}
                                    <span className='ResDetailsKm'>{rentalState === 1 ? 'Km' : ''}</span>
                                  </span>
                            }
                          </div>

                          <div className='ResDetailsInfo'>
                            <div className='ResDetailsUserSet flex mb-1.5'>
                              <div className='ResDetailsUserBg1'>
                                <span className='ResDetailsUserSpan1'>예약자</span>
                              </div>
                              <div className='ResDetailsUserBg2'>
                                <span className='ResDetailsUserSpan2'>{userInfo?.filter(({ user_id }) => user_id === userId)?.map(({ username }) => username)}</span>
                              </div>
                            </div>

                            <div className='ResDetailsPartnameSet flex mb-1.5'>
                              <div className='ResDetailsPartnameBg1'>
                                <span className='ResDetailsPartnameSpan1'>소속팀</span>
                              </div>
                              <div className='ResDetailsPartnameBg2'>
                                <span className='ResDetailsPartnameSpan2'>{userInfo?.filter(({ user_id }) => user_id === userId)?.map(({ partname }) => partname)}</span>
                              </div>
                            </div>

                            <div className='ResDetailsPositionSet flex mb-1.5'>
                              <div className='ResDetailsPositionBg1'>
                                <span className='ResDetailsPositionSpan1'>직급</span>
                              </div>
                              <div className='ResDetailsPositionBg2'>
                                <span className='ResDetailsPositionSpan2'>{userInfo?.filter(({ user_id }) => user_id === userId)?.map(({ position }) => position)}</span>
                              </div>
                            </div>

                            <div className='ResDetailsPhoneSet flex mb-1.5'>
                              <div className='ResDetailsPhoneBg1'>
                                <span className='ResDetailsPhoneSpan1'>전화번호</span>
                              </div>
                              <div className='ResDetailsPhoneBg2'>
                                <span className='ResDetailsPhoneSpan2'>{userInfo?.filter(({ user_id }) => user_id === userId)?.map(({ telephone }) => `${telephone?.slice(0, 3)}-${telephone?.slice(3, 7)}-${telephone?.slice(7, 11)}`)}</span>
                              </div>
                            </div>

                            <div className='ResDetailsStartSet flex mb-1.5'>
                              <div className='ResDetailsStartBg1'>
                                <span className='ResDetailsStartSpan1'>예약일</span>
                              </div>
                              <div className='ResDetailsStartBg2'>
                                <span className='ResDetailsStartSpan2'>{moment(startdate).format('YYYY-MM-DD')}<span className='text-sm ml-2'>{moment(startdate).format('HH:mm')}</span></span>
                              </div>
                            </div>

                            <div className='ResDetailsEndSet flex mb-1.5'>
                              <div className='ResDetailsEndBg1'>
                                <span className='ResDetailsEndSpan1'>반납일</span>
                              </div>
                              <div className='ResDetailsEndBg2'>
                                <span className='ResDetailsEndSpan2'>
                                  {rentalState === 1 ? moment(returnDate).format('YYYY-MM-DD') : moment(enddate).format('YYYY-MM-DD')}
                                  <span className='text-sm ml-2'>{rentalState === 1 ? moment(returnDate).format('HH:mm') : moment(enddate).format('HH:mm')}</span>
                                </span>
                              </div>
                            </div>

                            <div className='ResDetailsDestinationSet flex mb-1.5'>
                              <div className='ResDetailsDestinationBg1'>
                                <span className='ResDetailsDestinationSpan1'>출장지</span>
                              </div>
                              <div className='ResDetailsDestinationBg2'>
                                <span className='ResDetailsDestinationSpan2'>{rentalState === 1 ? destination : truncatePurposeDetail(purposeDetail)}</span>
                              </div>
                            </div>

                            <div className='ResDetailsParkingSet flex'>
                              <div className='ResDetailsParkingBg1'>
                                <span className='ResDetailsParkingSpan1'>주차</span>
                              </div>
                              <div className='ResDetailsParkingBg2'>
                                <span className='ResDetailsParkingSpan2'>{rentalState === 1 ? parking : ''}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='ResDetailsResNumBg'>
                          <div className='ResDetailsResNumTitle flex'>
                            <img src={BlueCalendar} alt="📆" className='ResDetailsResNumImg' />
                            <span className='ResDetailsResNumSpan1'>한달예약횟수</span>
                          </div>
                          <hr className='ResDetailsResNumHr' />
                          <div className='ResDetailsResNumContents'>
                            <span className='ResDetailsResNumSpan2'>
                              {resNumberPerMonth}
                              <span className='ResDetailsResNumSpan3'>회</span>
                            </span>
                          </div>
                          <div className='flex'>
                            <span className='ResDetailsResNumSpan4'>최근예약일</span>
                            <span className='ResDetailsResNumSpan5'>{lastResDate}</span>
                          </div>
                        </div>

                        <div className='ResDetailsDistanceNumBg'>
                          <div className='ResDetailsDistanceNumTitle flex'>
                            <img src={BlueCar} alt="🚙" className='ResDetailsDistanceNumImg' />
                            <span className='ResDetailsDistanceNumSpan1'>총운행거리</span>
                          </div>
                          <hr className='ResDetailsDistanceNumHr' />
                          <div>
                            { sumAllDistance > 1000
                              ? <span className='ResDetailsDistanceNumSpanB2'>
                                  {sumAllDistance?.toLocaleString()}
                                  <span className='ResDetailsDistanceNumSpanB3'>Km</span>
                                </span>
                              : <span className='ResDetailsDistanceNumSpanS2'>
                                  {sumAllDistance?.toLocaleString()}
                                  <span className='ResDetailsDistanceNumSpanS3'>Km</span>
                                </span>
                            }
                          </div>
                        </div>

                        <div className='ResDetailsChartBg'>
                          <div className='ResDetailsChartTitle flex'>
                            <img src={BlueCalendar} alt="📆" className='ResDetailsChartImg' />
                            <span className='ResDetailsChartSpan'>예약 Trend</span>
                          </div>

                          <div className='ResDetailsChartCarSet flex'>
                            <div className='ResDetailsChartCarM'>
                              <span className='ResDetailsChartCarSpanM'>MORNING</span>
                            </div>
                            <div className='ResDetailsChartCarT'>
                              <span className='ResDetailsChartCarSpanT'>TUCSON</span>
                            </div>
                          </div>
                          <hr className='ResDetailsChartHr' />

                          <div className='ResDetailsHiChart'>
                          <HighchartsReact 
                            highcharts={Highcharts}
                            options={initialOptions}
                            containerProps={{ className: "ResDetailsChartResNum" }}
                          />
                          </div>

                          <div className='ResDetailsChartContent'>
                            <div className='ResDetailsChartContentMSet mb-10'>
                              <span className='ResDetailsChartContentMSpan1'>MORNING</span>
                              <hr className='ResDetailsChartContentMHr' />
                              <div className='ResDetailsChartContentM flex'> 
                                <span className='ResDetailsChartContentMSpan2'>{rentalMInfo >=10 ? rentalMInfo : '0' + rentalMInfo}</span>
                                <img src={MorningImg} alt="🚗" className='ResDetailsChartContentMImg' />
                              </div>
                            </div>
                            <div className='ResDetailsChartContentTSet'>
                              <span className='ResDetailsChartContentTSpan1'>TUCSON</span>
                              <hr className='ResDetailsChartContentTHr' />
                              <div className='ResDetailsChartContentT flex'> 
                                <span className='ResDetailsChartContentTSpan2'>{rentalTInfo >= 10 ? rentalTInfo : '0' + rentalTInfo}</span>
                                <img src={TucsonImg} alt="🚓" className='ResDetailsChartContentTImg' />
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </form>
              )}
            </DialogBox>
            <div>
            <AdminResReturn adminResReturnOpen={adminResReturnOpen} setAdminResReturnOpen={setAdminResReturnOpen} selectUser={selectUser} selectRes={selectRes} selectCar={selectCar} setResDetailsOpen={setResDetailsOpen} />
            </div>
    </div>
  )
}

export default ResDetailsModal;