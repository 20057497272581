import axios from "axios";
import { useAuth } from "base/hook/Authcontext";
import useFieldValues from "base/hook/useFieldValues";
import { useEffect, useState } from "react";
import DialogBox from 'react-modeless';
import UserId from 'static/image/ResUsers747.png';
import UserPw from 'static/image/Pw198.png';
import UserName from 'static/image/ResUsers747.png';
import UserPart from 'static/image/ResPurpose769.png';
import UserPhone from 'static/image/Phone754.png';
import UserAuth from 'static/image/ResPD756.png';
import ModifyImage from 'static/image/ModifyImage.png';
import UserPosition from 'static/image/position805.png';
import 'css/UserModifyButton.css';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { usersGetAxios } from "base/redux/accounts/usersGetAxios";
import { usersData } from "base/redux/accounts/usersSlice";


const INIT_FILED_VALUES = {
    telephone: '',
    partname: '',
    position: '',
}

function UserModifyButton({ userModifyModalIsOpen, setUserModifyModalIsOpen, modifyModalIsOpen, setModifyModalIsOpen, selectedUserInfo }) {
    const [auth, , , logout] = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userInfo = useSelector(usersData);

    const { 
        fieldValues, 
        handleFieldChange, 
        emptyFieldValues,
    } = useFieldValues(INIT_FILED_VALUES);

    const handleUserModifyButton = async (e) => {
        e.preventDefault();

        await axios({
            url: `/api/users/${auth?.user_id}`,
            method: 'put',
            data: { telephone: fieldValues.telephone ? fieldValues.telephone : auth?.telephone },
        })
        .then(function(response) {
            logout();
            navigate('/');
        })
        .catch(function(error) {
            console.log(error);
        })
        
        await axios({
            url: `/api/users/pw/${auth?.user_id}`,
            method: 'put',
            data: { password: fieldValues.password ? fieldValues.password : auth?.password },
        })
        .then(function(response) {
            logout();
            navigate('/');
        })
        .catch(function(error) {
            console.log(error);
        })
    };

    const handleAdminModifyButton = async (e) => {
        e.preventDefault();
            await axios({
                url: `/api/users/${selectedUserInfo?.user_id}`,
                method: 'put',
                data: { 
                    telephone: fieldValues.telephone ? fieldValues.telephone : selectedUserInfo?.telephone, 
                    partname: fieldValues.partname ? fieldValues.partname : selectedUserInfo?.partname, 
                    position: fieldValues.position ? fieldValues.position : selectedUserInfo?.position 
                },
            })
            .then(function(response) {
                setUserModifyModalIsOpen(false)
                dispatch(usersGetAxios());
                emptyFieldValues();
            })
            .catch(function(error) {
                console.log(error);
            })
            
            await axios({
                url: `/api/users/pw/${selectedUserInfo?.user_id}`,
                method: 'put',
                data: { password: fieldValues.password ? fieldValues.password : selectedUserInfo?.password },
            })
            .then(function(response) {
                setUserModifyModalIsOpen(false);
                dispatch(usersGetAxios());
                emptyFieldValues();
            })
            .catch(function(error) {
                console.log(error);
            })
    };

    return (
        <div> 
            {auth?.authority === 0 ? 
                (
                    <DialogBox
                        ariaHideApp={false}
                        isOpen={modifyModalIsOpen}
                        onRequestClose={() => setModifyModalIsOpen(false)}
                        shouldCloseOnOverlayClick={false}
                        style={{
                            backgroundColor: 'rgba(58, 61, 63, 0.8)',
                            width: '100%',
                            height: '100vh',
                        }}
                            >
                        <form onSubmit={handleUserModifyButton} className="ModifyModal">
                            <div className="ModifyModalBg">
                                <div className="absolute top-[135px] right-[625px]">
                                    <button className="ModifyXButton" onClick={() => [emptyFieldValues(), setModifyModalIsOpen(false)]}>
                                        <span className="ModifyXButtonSpan">X</span>
                                    </button>
                                </div>
                                <div className="ModifyModalBB">
                                    <span className='ModifyModalSpan1'>+</span>
                                    <span className="ModifyModalSpan2">회원 정보 수정</span>
                                    <p className="ModifyModalP">* 만 수정 가능합니다.</p>
                                </div>

                                <div className="ModifyIdSet flex">
                                    <img src={UserId} alt="◽" className="ModifyIdImage" />
                                    <label htmlFor="userId" className="ModifyIdLabel">아이디</label>
                                    <h1 className="ModifyIdH1">{auth?.user_id}</h1>
                                </div>

                                <div className="ModifyPWSet flex">
                                    <img src={UserPw} alt="◽" className="ModifyPWImage" />
                                    <label htmlFor="password" className="ModifyPWLabel">비밀번호</label>
                                    <span className="ModifyPWSpan">*</span>
                                    <input type="password" name="password" id="password" value={fieldValues.password} onChange={handleFieldChange} className="ModifyPWInput" placeholder="************" />
                                </div>

                                <div className="ModifyNameSet flex">
                                    <img src={UserName} alt="◽" className="ModifyNameImage" />
                                    <label htmlFor="userName" className="ModifyNameLabel">이름</label>
                                    <h1 className="ModifyNameH1">{auth?.username}</h1>
                                </div>

                                <div className="ModifyPartSet flex">
                                    <img src={UserPart} alt="◽" className="ModifyPartImage" />
                                    <label htmlFor="" className="ModifyPartLabel">소속</label>
                                    <h1 className="ModifyPartH1">{auth?.partname}</h1>
                                </div>

                                <div className="ModifyPositionSet flex">
                                    <img src={UserPosition} alt="◽" className="ModifyPositionImage" />
                                    <label htmlFor="" className="ModifyPositionLabel">직위</label>
                                    <h1 className="ModifyPositionH1">{auth?.position}</h1>
                                </div>

                                <div className="ModifyPhoneSet flex">
                                    <img src={UserPhone} alt="◽" className="ModifyPhoneImage" />
                                    <label htmlFor="" className="ModifyPhoneLabel">전화번호</label>
                                    <span className="ModifyPWSpan">*</span>
                                    <input type="telephone" name="telephone" id="telephone" value={fieldValues.telephone} onChange={handleFieldChange} className="ModifyPhoneInput" placeholder={auth?.telephone} maxLength='11' pattern="^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$" title="숫자로 휴대폰 번호를 적어주세요" />
                                </div>

                                <div className="ModifyAuthoritySet flex">
                                    <img src={UserAuth} alt="◽" className="ModifyAuthImage" />
                                    <label htmlFor="" className="ModifyAuthLabel">권한</label>
                                    <h1 className="ModifyAuthH1">{auth?.authority === 0 ? '일반회원' : '관리자'}</h1>
                                </div>

                                <div className="ModifyButtonSet flex justify-end">
                                    <button className="ModifyCancelButton" type="button" onClick={() => [emptyFieldValues(), setModifyModalIsOpen(false)]}>
                                        <span className="ModifyCancelSpan">취소</span>
                                    </button>
                                    <button className="ModifyButton" type="submit">
                                        <span className="ModifySpan">수정</span>
                                    </button>
                                </div>

                                <img src={ModifyImage} alt="" className="ModifyUserImage" />
                            </div>
                        </form>
                    </DialogBox>
                )
                :
                (
                    <>                      
                        <DialogBox
                            ariaHideApp={false}
                            isOpen={userModifyModalIsOpen}
                            onRequestClose={() => setUserModifyModalIsOpen(false)}
                            shouldCloseOnOverlayClick={false}
                            style={{
                                backgroundColor: 'rgba(58, 61, 63, 0.8)',
                                width: '100%',
                                height: '100vh',
                            }}
                        >
                            <form onSubmit={handleAdminModifyButton} className="ModifyModal">
                                <div className="ModifyModalBg">
                                    <div className="absolute top-[135px] right-[625px]">
                                        <button className="ModifyXButton" onClick={() => [emptyFieldValues(), setUserModifyModalIsOpen(false)]}>
                                            <span className="ModifyXButtonSpan">X</span>
                                        </button>
                                    </div>
                                    <div className="ModifyModalBB">
                                        <span className='ModifyModalSpan1'>+</span>
                                        <span className="ModifyModalSpan2">회원 정보 수정</span>
                                        <p className="ModifyModalP">* 만 수정 가능합니다.</p>
                                    </div>

                                    <div className="ModifyIdSet flex">
                                        <img src={UserId} alt="◽" className="ModifyIdImage" />
                                        <label htmlFor="userId" className="ModifyIdLabel">아이디</label>
                                        <h1 className="ModifyIdH1">{selectedUserInfo?.user_id}</h1>
                                    </div>

                                    <div className="ModifyPWSet flex">
                                        <img src={UserPw} alt="◽" className="ModifyPWImage" />
                                        <label htmlFor="password" className="ModifyPWLabel">비밀번호</label>
                                        <span className="ModifyPWSpan">*</span>
                                        <input type="password" name="password" id="password" value={fieldValues.password} onChange={handleFieldChange} className="ModifyPWInput" placeholder="************" />
                                    </div>

                                    <div className="ModifyNameSet flex">
                                        <img src={UserName} alt="◽" className="ModifyNameImage" />
                                        <label htmlFor="userName" className="ModifyNameLabel">이름</label>
                                        <h1 className="ModifyNameH1">{selectedUserInfo?.username}</h1>
                                    </div>

                                    <div className="ModifyPartSet flex">
                                        <img src={UserPart} alt="◽" className="ModifyPartImage" />
                                        <label htmlFor="partname" className="ModifyPartLabel">소속</label>
                                        <span className="ModifyPartSpan">*</span>
                                        <input type="partname" name="partname" id="partname" value={fieldValues.partname} onChange={handleFieldChange} className="ModifyPartInput" placeholder={selectedUserInfo?.partname} maxLength='20' pattern="^[A-Z|0-9|가-힣\s]+$" title="부서명을 정확히 작성해주세요" />
                                    </div>

                                    <div className="ModifyPositionSet flex">
                                        <img src={UserPosition} alt="◽" className="ModifyPositionImage" />
                                        <label htmlFor="position" className="ModifyPositionLabel">직위</label>
                                        <span className="ModifyPositionSpan">*</span>
                                        <input type="position" name="position" id="position" value={fieldValues.position} onChange={handleFieldChange} className="ModifyPositionInput" placeholder={selectedUserInfo?.position} maxLength='10' pattern="^[가-힣\s]+$" title="직위를 한글로 작성해주세요" />
                                    </div>

                                    <div className="ModifyPhoneSet flex">
                                        <img src={UserPhone} alt="◽" className="ModifyPhoneImage" />
                                        <label htmlFor="" className="ModifyPhoneLabel">전화번호</label>
                                        <span className="ModifyPWSpan">*</span>
                                        <input type="telephone" name="telephone" id="telephone" value={fieldValues.telephone} onChange={handleFieldChange} className="ModifyPhoneInput" placeholder={selectedUserInfo?.telephone} maxLength='11' pattern="^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$" title="숫자로 휴대폰 번호를 적어주세요" />
                                    </div>

                                    <div className="ModifyAuthoritySet flex">
                                        <img src={UserAuth} alt="◽" className="ModifyAuthImage" />
                                        <label htmlFor="" className="ModifyAuthLabel">권한</label>
                                        <h1 className="ModifyAuthH1">{selectedUserInfo?.authority === 0 ? '일반회원' : '관리자'}</h1>
                                    </div>

                                    <div className="ModifyButtonSet flex justify-end">
                                        <button className="ModifyCancelButton" type="button" onClick={() => [emptyFieldValues(), setUserModifyModalIsOpen(false)]}>
                                            <span className="ModifyCancelSpan">취소</span>
                                        </button>
                                        <button className="ModifyButton" type="submit">
                                            <span className="ModifySpan">수정</span>
                                        </button>
                                    </div>

                                    <img src={ModifyImage} alt="" className="ModifyImage" />
                                </div>
                            </form>
                        </DialogBox> 
                    </>               
                )
            }
        </div>
    )
}

export default UserModifyButton;