import DashBoard from "components/main/admin/DashBoard";


function AdminPage() {
    return (
        <div>
            <DashBoard />
        </div>
    )
}

export default AdminPage;