import { createSlice } from '@reduxjs/toolkit'


export const reservationsSlice = createSlice({
    name: 'reservations',
    initialState: {
        resData: [],
    },
    reducers: {
        putRes: (state, action) => {
            return {...state, resData: [...action.payload]}
        },
        addRes: (state, action) => {
            state.resData = action.payload
        },
        returnRes: (state, action) => {
            state.resData = action.payload
        },
        deleteRes: (state, action) => {
            state.resData = action.payload
        },
        partnameRes: (state, action) => {
            state.resData = action.payload
        },
    },
})

export const { putRes, addRes, returnRes, deleteRes, partnameRes } = reservationsSlice.actions;
export const resData = state => state?.reservations?.resData;
export default reservationsSlice.reducer;