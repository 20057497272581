import 'css/DashBoard.css'
import Users from 'static/image/Users.png';
import Logo from 'static/image/Logo203@3x.png';
import DashSU from 'static/image/userInfo405.png';
import DashSL from 'static/image/reservation398.png';
import DashSC from 'static/image/AdminCar400.png';
import DashSUB from 'static/image/userInfoBlue405.png';
import DashSLB from 'static/image/reservationBlue398.png';
import DashSCB from 'static/image/AdminCarBlue400.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';


function DashSelect() {
  const navigate = useNavigate();
  const location = useLocation();
  const [userClick, setUserClick] = useState(() => location?.pathname === '/admin/users/');
  const [resClick, setResClick] = useState(() => location?.pathname === '/admin/reservations/');
  const [carClick, setCarClick] = useState(() => location?.pathname === '/admin/cars/');

    return (
        <div className="dashSelectBg select-none">
          <img src={Users} alt="유저" className='dashSelectImage' />
          <p className='dashSelectP1'>MANAGER</p>

          <div className='dashSelectDot'>
            <img src={Logo} alt="CARBBLE" className='dashSelectLogo' onClick={() => navigate(`/`)} />
          </div>
        
          <p className='dashSelectP2'>ASSEMBBLE CAR MANAGEMENT SYSTEM</p>

          <div className='dashSelectButtonSet'>
            <div className='dashSelectUserSet' onClick={() => {navigate(`/admin/users/`);}}>
              <button 
                className={`${
                            userClick
                                && 'userClick'
                            }
                            dashSelectUserButton
                        `}
                onClick={() => setUserClick(true)}
                onClickCapture={() => {setResClick(false); setCarClick(false);}}
              >
                <img src={`${userClick ? DashSUB : DashSU}`} alt="👔" className='dashSelectUserImage' />
                <span className={`${userClick && 'userClick'} dashSelectUserSpan`}>회원관리</span>
              </button>
            </div>
            
            <div className='dashSelectListSet' onClick={() => {navigate(`/admin/reservations/`);}}>
              <button className={`${
                            resClick
                                && 'resClick'
                            }
                            dashSelectListButton
                        `}
                onClick={() => setResClick(true)}
                onClickCapture={() => {setUserClick(false); setCarClick(false);}}
              >
                <img src={`${resClick ? DashSLB : DashSL}`} alt="📄" className='dashSelectListImage' />
                <span className={`${resClick && 'resClick'} dashSelectListSpan`}>대여내역</span>
              </button>
            </div>

            <div className='dashSelectCarSet' onClick={() => {navigate(`/admin/cars/`);}}>
              <button className={`${
                            carClick
                                && 'carClick'
                            }
                            dashSelectCarButton
                        `}
                onClick={() => setCarClick(true)}
                onClickCapture={() => {setUserClick(false); setResClick(false);}}        
              >
                <img src={`${carClick ? DashSCB : DashSC}`} alt="⚙" className='dashSelectCarImage' />
                <span className={`${carClick && 'carClick'} dashSelectCarSpan`}>차량관리</span>
              </button>
            </div>
          </div>
        </div>
    );
};

export default DashSelect;