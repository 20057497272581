import DialogBox from 'react-modeless';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CarInspectionCarImg from 'static/image/DashT494.png';
import BlueCalendar from 'static/image/Calendar650.png';
import 'css/CarInspectionModal.css';
import { checkPutAxios } from "base/redux/check/checkPutAxios";
import { checkData } from "base/redux/check/checkSlice";
import FullCalendar from "@fullcalendar/react";
import interaction from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import 'css/FullCalendar.css';
import 'css/FCList.css';
import axios from 'axios';
import moment from "moment";


function CarInspectionTModal({ inspectionModalTIsOpen, setInspectionModalTIsOpen }) {
  const checkInfo = useSelector(checkData);
  const dispatch = useDispatch();
  const years = new Date().getFullYear();
  const biggestCheckId = checkInfo?.filter(({ carId }) => carId === 1)?.map(({ checkId }) => checkId);
  const recentTInfo = checkInfo?.find(({ checkId }) => Math.max(...biggestCheckId) === checkId)

  useEffect(() => {
    dispatch(checkPutAxios({ startdate: years + '-01-01', enddate: years + '-12-31' }));
}, []);

return(
    <div>
      <DialogBox            
        ariaHideApp={false}
        isOpen={inspectionModalTIsOpen}
        onRequestClose={() => setInspectionModalTIsOpen(false)}
        shouldCloseOnOverlayClick={false}
        className="userAddModal shadow-md outline-none"
        style={{
          backgroundColor: 'rgba(58, 61, 63, 0.8)',
          width: '100%',
          height: '100vh',
        }}
      >

          <form className="CarInspectionModal" key={recentTInfo?.checkId}>
            <div className="CarInspectionModalBg">
              <div className="relative top-[40px] left-[1075px]">
                <button className="CarInspectionXButton" onClick={() => setInspectionModalTIsOpen(false)}>
                  <span className="CarInspectionXButtonSpan">X</span>
                </button>
              </div>
                          
              <div className="CarInspectionBB">
                <span className='CarInspectionSpan1'>+</span>
                <span className="CarInspectionSpan2">점검내역 자세히보기</span>
              </div>
                    
              <div className='CarInspectionBg'>
                <div className='ResDetailCarSet'>
                  <img src={CarInspectionCarImg} alt="🚘" className='CarInspectionCarImg' />
                  <span className='CarInspectionDistance'>{recentTInfo?.checkMileage ? recentTInfo?.checkMileage?.toLocaleString() : ''}
                    <span className='CarInspectionKm'>{recentTInfo?.checkMileage ? 'Km' : ''}</span>
                  </span>
                </div>

                <div className='CarInspectionInfo'>
                  <div className='CarInspectionUserSet flex mb-1.5'>
                    <div className='CarInspectionUserBg1'>
                      <span className='CarInspectionUserSpan1'>차량</span>
                    </div>
                    <div className='CarInspectionUserBg2'>
                      <span className='CarInspectionUserSpan2'>{recentTInfo?.carId === 1 ? '투싼' : ''}</span>
                    </div>
                  </div>

                  <div className='CarInspectionUserSet flex mb-1.5'>
                    <div className='CarInspectionUserBg1'>
                      <span className='CarInspectionUserSpan1'>점검일</span>
                    </div>
                    <div className='CarInspectionUserBg2'>
                      <span className='CarInspectionUserSpan2'>{recentTInfo?.checkDate}</span>
                    </div>
                  </div>

                  <div className='CarInspectionPartnameSet'>
                    <div className='CarInspectionPartnameBg1'>
                      <span className='CarInspectionPartnameSpan1'>점검내용</span>
                    </div>
                    <div className='CarInspectionPartnameBg2'>
                      <span className='CarInspectionPartnameSpan2'>{recentTInfo?.checkContent}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='CarInspectionChartBg'>
                <div className='CarInspectionChartTitle flex'>
                  <img src={BlueCalendar} alt="📆" className='CarInspectionChartImg' />
                  <span className='CarInspectionChartSpan'>점검 List</span>
                </div>

                <div className='CarInspectionChartCarSet flex'>
                  <div className='CarInspectionChartCarT'>
                    <span className='CarInspectionChartCarSpanT'>TUCSON</span>
                  </div>
                </div>
                <hr className='CarInspectionChartHr' />
              </div>

              <div className="carInspectionCList">
                <FullCalendar 
                  plugins={[ listPlugin, interaction ]}
                  initialView="listYear"
                  locale="ko"
                  height="auto"
                  headerToolbar={
                    {
                      start: 'prev',
                      center: 'title',
                      end: 'next'
                    }
                  }
                  events={
                    checkInfo
                      ?.filter(({ carId }) => carId === 1)
                      ?.map(({ carId, checkId, checkDate, checkContent, checkMileage }) => {
                        return (
                          {
                            title: [`${carId === 1 ? '투싼' : ''} ${checkMileage?.toLocaleString()} Km ▶ ${checkContent}`],
                            start: checkDate,
                            color: carId === 1 ? "#ff6767" :  "",
                            id: checkId,
                          }
                        )
                      }
                    )
                  }
                  eventClick={
                    function(info) {
                      window.confirm(`${moment(info.event.start).format('YYYY-MM-DD')}일 점검내역을 삭제하시겠습니까?`) &&
                        axios({
                          url: `/api/check/${info.event.id}`,
                          method: 'delete',
                          data: { checkId: info.event.id },
                        })
                        .then(function(response) {
                          dispatch(checkPutAxios({ startdate: years + '-01-01', enddate: years + '-12-31' }));
                        })
                        .catch(function(error) {
                          console.log(error);
                        }
                      )
                    }
                  }
                />
              </div>
            </div>
          </form>
      </DialogBox>
    </div>
  )
}

function CarInspectionMModal({ inspectionModalMIsOpen, setInspectionModalMIsOpen }) {
  const checkInfo = useSelector(checkData);
  const dispatch = useDispatch();
  const years = new Date().getFullYear();
  const biggestCheckId = checkInfo?.filter(({ carId }) => carId === 2)?.map(({ checkId }) => checkId);
  const recentMInfo = checkInfo?.find(({ checkId }) => Math.max(...biggestCheckId) === checkId)

  useEffect(() => {
    dispatch(checkPutAxios({ startdate: years + '-01-01', enddate: years + '-12-31' }));
}, []);

return(
    <div>
      <DialogBox            
        ariaHideApp={false}
        isOpen={inspectionModalMIsOpen}
        onRequestClose={() => setInspectionModalMIsOpen(false)}
        shouldCloseOnOverlayClick={false}
        className="userAddModal shadow-md outline-none"
        style={{
          backgroundColor: 'rgba(58, 61, 63, 0.8)',
          width: '100%',
          height: '100vh',
        }}
      >

          <form className="CarInspectionModal" key={recentMInfo?.checkId}>
            <div className="CarInspectionModalBg">
              <div className="relative top-[40px] left-[1075px]">
                <button className="CarInspectionXButton" onClick={() => setInspectionModalMIsOpen(false)}>
                  <span className="CarInspectionXButtonSpan">X</span>
                </button>
              </div>
                          
              <div className="CarInspectionBB">
                <span className='CarInspectionSpan1'>+</span>
                <span className="CarInspectionSpan2">점검내역 자세히보기</span>
              </div>
                    
              <div className='CarInspectionBg'>
                <div className='ResDetailCarSet'>
                  <img src={CarInspectionCarImg} alt="🚘" className='CarInspectionCarImg' />
                  <span className='CarInspectionDistance'>{recentMInfo?.checkMileage ? recentMInfo?.checkMileage?.toLocaleString() : ''}
                    <span className='CarInspectionKm'>{recentMInfo?.checkMileage ? 'Km' : ''}</span>
                  </span>
                </div>

                <div className='CarInspectionInfo'>
                  <div className='CarInspectionUserSet flex mb-1.5'>
                    <div className='CarInspectionUserBg1'>
                      <span className='CarInspectionUserSpan1'>차량</span>
                    </div>
                    <div className='CarInspectionUserBg2'>
                      <span className='CarInspectionUserSpan2'>{recentMInfo?.carId === 2 ? '모닝' : ''}</span>
                    </div>
                  </div>

                  <div className='CarInspectionUserSet flex mb-1.5'>
                    <div className='CarInspectionUserBg1'>
                      <span className='CarInspectionUserSpan1'>점검일</span>
                    </div>
                    <div className='CarInspectionUserBg2'>
                      <span className='CarInspectionUserSpan2'>{recentMInfo?.checkDate}</span>
                    </div>
                  </div>

                  <div className='CarInspectionPartnameSet'>
                    <div className='CarInspectionPartnameBg1'>
                      <span className='CarInspectionPartnameSpan1'>점검내용</span>
                    </div>
                    <div className='CarInspectionPartnameBg2'>
                      <span className='CarInspectionPartnameSpan2'>{recentMInfo?.checkContent}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='CarInspectionChartBg'>
                <div className='CarInspectionChartTitle flex'>
                  <img src={BlueCalendar} alt="📆" className='CarInspectionChartImg' />
                  <span className='CarInspectionChartSpan'>점검 List</span>
                </div>

                <div className='CarInspectionChartCarSet flex'>
                  <div className='CarInspectionChartCarM'>
                    <span className='CarInspectionChartCarSpanM'>MORNING</span>
                  </div>
                </div>
                <hr className='CarInspectionChartHr' />
              </div>

              <div className="carInspectionCList">
                <FullCalendar 
                  plugins={[ listPlugin, interaction ]}
                  initialView="listYear"
                  locale="ko"
                  height="auto"
                  headerToolbar={
                    {
                      start: 'prev',
                      center: 'title',
                      end: 'next'
                    }
                  }
                  events={
                    checkInfo
                      ?.filter(({ carId }) => carId === 2)
                      ?.map(({ carId, checkId, checkDate, checkContent, checkMileage }) => {
                        return (
                          {
                            title: [`${carId === 2 ? '모닝' : ''} ${checkMileage?.toLocaleString()} Km ▶ ${checkContent}`],
                            start: checkDate,
                            color: carId === 2 ? "#26c9a8" :  "",
                            id: checkId,
                          }
                        )
                      }
                    )
                  }
                  eventClick={
                    function(info) {
                      window.confirm(`${moment(info.event.start).format('YYYY-MM-DD')}일 점검내역을 삭제하시겠습니까?`) &&
                        axios({
                          url: `/api/check/${info.event.id}`,
                          method: 'delete',
                          data: { checkId: info.event.id },
                        })
                        .then(function(response) {
                          dispatch(checkPutAxios({ startdate: years + '-01-01', enddate: years + '-12-31' }));
                        })
                        .catch(function(error) {
                          console.log(error);
                        }
                      )
                    }
                  }
                />
              </div>
            </div>
          </form>
      </DialogBox>
    </div>
  )
}

export { CarInspectionTModal, CarInspectionMModal };