import { useAuth } from 'base/hook/Authcontext';
import { resData } from 'base/redux/reservations/reservationsSlice';
import 'css/RecentRes.css';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Morning from 'static/image/Morning689.png';
import Tucson from 'static/image/Tucson690.png';


function RecentRes() {
    const [auth] = useAuth();
    const resInfo = useSelector(resData);
    const tResId = resInfo?.filter(({ rentalState }) => rentalState === 1)?.filter(({ carId }) => carId === 1)?.map(({ reservationId}) => reservationId);
    const mResId = resInfo?.filter(({ rentalState }) => rentalState === 1)?.filter(({ carId }) => carId === 2)?.map(({ reservationId}) => reservationId);

    return (
        <div className="RecentBg select-none">
            <div className="RecentBB">
                <span className='RecentP'>+</span>
                <span className="RecentSpan">최근예약</span>
            </div>

            <div className="RecentMorningSet flex">
                <div className='RecentMorningImageSet'>
                    <p className="RecentMorningImageP">MORNING</p>
                    <img src={Morning} alt="🚗" className="RecentMorningImage" />
                </div>
                <div className='RecentMorningWordSet'>
                    { resInfo
                        ?.filter(({ userId }) => auth?.user_id === userId)
                        ?.filter(({ rentalState, carId, reservationId }) => rentalState === 1 && carId === 2 && reservationId === Math.max(...mResId))
                        ?.map(({ distance, startdate }) =>
                        <>
                            <h1 className="RecentMorningH1">{distance ? distance?.toLocaleString() : ''}Km</h1>
                            <p className="RecentMorningP">{moment(startdate).format('YYYY-MM-DD HH:mm')}</p>
                        </>
                    )}
                </div>
            </div>
            <hr className='RecentHr' />
            <div className="RecentTucsonSet flex">
                <div className='RecentTucsonImageSet'>
                    <p className="RecentTucsonImageP">TUCSON</p>
                    <img src={Tucson} alt="🚙" className="RecentTucsonImage" />
                </div>
                <div className='RecentTucsonWordSet'>
                    { resInfo
                        ?.filter(({ userId }) => auth?.user_id === userId)
                        ?.filter(({ rentalState, carId, reservationId }) => rentalState === 1 && carId === 1 && reservationId === Math.max(...tResId))
                        ?.map(({ distance, startdate }) =>
                        <>
                            <h1 className="RecentTucsonH1">{distance ? distance?.toLocaleString() : '000'}Km</h1>
                            <p className="RecentMorningP">{moment(startdate).format('YYYY-MM-DD HH:mm')}</p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RecentRes;