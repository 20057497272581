import CarManagement from "components/main/admin/CarManagement";
import DashBoard from "components/main/admin/DashBoard";
import ResManagement from "components/main/admin/ResManagement";
import UserManagement from "components/main/admin/UserManagement";
import ErrorForbidden from "components/parts/ErrorForbidden";
import NotFound from "components/parts/NotFound";
import AdminPage from "pages/admin/AdminPage";
import { Navigate, Route, Routes } from "react-router-dom";

function AdminRouter() {
    return (
        <div className="z-0">
            <Routes>
                <Route path="/" element={<Navigate to = "/admin/dashBoard/" />}/>

                <Route path="/" element={<AdminPage />} />
                <Route path="/dashBoard/" element={<DashBoard />} />
                <Route path="/users/" element={<UserManagement />} />
                <Route path="/reservations/" element={<ResManagement />} />
                <Route path="/cars/" element={<CarManagement />} />
                
                <Route path="/403/" element={<ErrorForbidden />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
    );
}

export default AdminRouter;