import axios from "axios"
import { emergencyPutAxios } from "./emergencyPutAxios";

const years = new Date().getFullYear();
const startYears = years + '-01-01';
const endMonth = years + '-12-31';

export function emergencyPostAxios(emergencyData) {
        return async function emergencyPostThunk(dispatch, getState) {
            await axios.post(`/api/emergency`, emergencyData)
            dispatch(emergencyPutAxios({ startdate: startYears, enddate: endMonth,}))
        }
    }