import { usersGetAxios } from 'base/redux/accounts/usersGetAxios';
import { reservationsPutAxios } from 'base/redux/reservations/reservationsPutAxios';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addYears, addMonths } from 'date-fns';
import { usersData } from 'base/redux/accounts/usersSlice';


function useFieldValues(initialValues) {
  const [fieldValues, setFieldValues] = useState(initialValues);
  const [formData, setFormData] = useState(new FormData());
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const startYear = addYears(new Date(), -1);
  const endMonth = addMonths(new Date(), 1);
  const dispatch = useDispatch();
  const userInfo = useSelector(usersData);
  // const isKorean = /^[ㄱ-ㅣ|가-힣]+$/;
  // const isName = /^[가-힣]+$/;
  // const isId = /^[0-9]+$/;

  useEffect(() => {
    dispatch(reservationsPutAxios({ startdate: startYear, enddate: endMonth }))
    dispatch(usersGetAxios())
  }, []);


  const submitForm = () => {
    console.log(fieldValues);
  }

  // 함수 객체를 생성할 때, 의존성이 걸린 값이 변경시에만 함수를 재생성
  const handleFieldChange = useCallback((e) => {
    const { type, name, value, files, checked } = e.target;

    let newValue;
    if (type === 'file') {
      newValue = Array.from(files);
    } else if (type === 'checkbox') {
      newValue = checked;
    } else {
      newValue = value;
    }

    setFieldValues((prevFieldValues) => {
      return {
        ...prevFieldValues,
        [name]: newValue,
      };
    });
  }, []);

  const handleSubmit = (e, values) => {
    e.preventDefault();
    setFormErrors(validate(values));
    setIsSubmitting(true);
  }

  const validate = (values) => {
    let errors = {};

      if (userInfo?.filter(({ user_id }) => user_id === parseInt(values.id))?.map(({ user_id }) => user_id)) {
        errors.id = "사원 번호를 확인해주세요";
      }

      // if (!isPassword.test(values.password)) {
      //   errors.password = "비밀번호를 확인해주세요";
      // }
        
      // if (!values.username) {
      //   errors.username = "사용자 이름을 적어주세요";
      // } else if (values.username.length <= 1) {
      //   errors.username = "두글자 이상 적어주세요";
      // } else if (!isKorean.test(values.username)) {
      //   errors.username = "한글로 이름을 적어주세요"
      // } else if (!isName.test(values.username)) {
      //   errors.username = "실제 이름을 적어주세요"
      // }

      // if (values.purposeDetail.length >= 101) {
      //   errors.purposeDetail = "100자 이내로 적어주세요"
      // } else if (!isTextarea.test(values.purposeDetail)) {
      //   errors.purposeDetail = "한글 단어로 적어주세요"
      // }
        
      return errors;
  }

  const emptyFieldValues = useCallback(() => {
    setFieldValues(initialValues);
  }, [initialValues]);

  // fieldValues 변경 시마다 formData를 갱신합니다.
  useEffect(() => {
    setFormData(
      Object.entries(fieldValues).reduce((_formData, [name, value]) => {
        if (Array.isArray(value)) {
          const fileList = value;
          fileList.forEach((file) => _formData.append(name, file));
        } else {
          _formData.append(name, value);
        }
        return _formData;
      }, new FormData()),
    );
  }, [fieldValues]);

  // initialValues 속성값이 변경되면 fieldValues를 초기화합니다.
  useEffect(() => {
    setFieldValues(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
        submitForm();
    }
}, [formErrors]);


  return {
    fieldValues,
    handleFieldChange,
    emptyFieldValues,
    setFieldValues,
    formData,
    formErrors,
    isSubmitting,
    handleSubmit,
  };
}

export default useFieldValues;