import photo from 'static/image/403.png'


function ErrorForbidden() {
    return (
        <div>
            <div>
                <div className="flex justify-center">
                    <img className="w-1/4 h-1/4" src={photo} alter="403 forbidden"/>
                </div>
            </div>
        </div>
    );
}

export default ErrorForbidden;