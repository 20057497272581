import useFieldValues from "base/hook/useFieldValues";
import DialogBox from 'react-modeless'
import { reservationsReturnAxios } from "base/redux/reservations/reservationsReturnAxios";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ReturnModalCar from 'static/image/ResModalCar771.png';
import ReturnDate from 'static/image/Return650.png';
import ReturnUser from 'static/image/ResUsers747.png';
import ReturnCheck from 'static/image/ResPD756.png';
import ReturnRefuel from 'static/image/ResPurpose769.png';
import ReturnParking from 'static/image/ResCars767.png';
import 'css/ResReturnButton.css';
import { useEffect } from "react";
import { resData } from "base/redux/reservations/reservationsSlice";
import { usersData } from "base/redux/accounts/usersSlice";
import { usersGetAxios } from "base/redux/accounts/usersGetAxios";


const INIT_FILED_VALUES = {
    destination: '',
    besides: '',
    damage: '',
    dashboard: '',
    lighting: '',
    mileage: '',
    parking: '',
    refueling_amount: '0',
    refuelingCheck: false,
}

function AdminResReturn ({ selectUser, selectRes, selectCar, adminResReturnOpen, setAdminResReturnOpen, setResDetailsOpen}) {
    const dispatch = useDispatch();
    const resInfo = useSelector(resData);
    const userInfo = useSelector(usersData);

    const { 
        fieldValues, 
        handleFieldChange, 
        emptyFieldValues,
    } = useFieldValues(INIT_FILED_VALUES);
    
    const onFetchClicked = (e) => {
        e.preventDefault();

        dispatch(reservationsReturnAxios({ ...fieldValues, refuelingCheck: parseInt(fieldValues.refueling_amount) === 0 ? false : true, refueling_amount: parseInt(fieldValues.refueling_amount), mileage: parseInt(fieldValues.mileage), reservationId: parseInt(selectRes), car_id: parseInt(selectCar) }))
    }

    useEffect(() => {
      dispatch(usersGetAxios());
    }, [])

    useEffect(() => {
        emptyFieldValues(); 
        setAdminResReturnOpen(false);
    }, [resInfo])

    return(
        <>
            <DialogBox
                ariaHideApp={false}
                isOpen={adminResReturnOpen}
                onRequestClose={() => setAdminResReturnOpen(false)}
                shouldCloseOnOverlayClick={false}
                clickBackdropToClose={false}
                style={{
                    backgroundColor: 'rgba(58, 61, 63, 0.8)',
                    width: '100%',
                    height: '100vh',
                }}  
            >

            <form onSubmit={onFetchClicked} className="ReturnModal">
                <div className="ReturnModalBg">
                    <div className="absolute top-[60px] right-[655px]">
                        <button className="ReturnXButton" onClick={() => [emptyFieldValues(), setAdminResReturnOpen(false), setResDetailsOpen(true)]}>
                            <span className="ReturnXButtonSpan">X</span>
                        </button>
                    </div>
                    <div className="ReturnModalBB">
                        <span className='ReturnModalSpan1'>+</span>
                        <span className="ReturnModalSpan2">반납하기</span>
                    </div>

                    <div className="flex">
                        <img src={ReturnDate} alt="◽" className="ReturnDateImage" />
                        <label htmlFor="" className="ReturnDateLabel">반납일</label>
                        <input type="text" placeholder={moment(new Date()).format('YYYY-MM-DD HH:mm')} disabled={true} className="ReturnDateInput" />
                    </div>

                    <div className="flex">
                        <img src={ReturnUser} alt="◽" className="ReturnUserImage" />
                        <label htmlFor="" className="ReturnUserLabel">운전자</label>
                        <input type="text" placeholder={userInfo?.filter(({ user_id }) => user_id === parseInt(selectUser))?.map(({ username }) => username)} disabled={true} className="ReturnUserInput" />
                    </div>

                    <div>
                        <div className="ReturnDestinationBB flex">
                            <label htmlFor="destination" className="ReturnDestinationLabel">목적지</label>
                            <input type="destination" name="destination" id="destination" placeholder="다녀온 지역명 예) 화성" required={true} className="ReturnDestinationInput" value={fieldValues.destination} onChange={handleFieldChange} maxLength='10' pattern="^[가-힣]+$" title="지역명을 한글로 정확히 작성해주세요" />
                        </div>
                    </div>

                    <div>
                        <div className="ReturnMileageBB flex">
                            <label htmlFor="mileage" className="ReturnMileageLabel">누적주행거리</label>
                            <input type="mileage" name="mileage" id="mileage" placeholder="계기판 숫자를 적어주세요" required={true} className="ReturnMileageInput" value={fieldValues.mileage} onChange={handleFieldChange} pattern="^[0-9]+$" title="계기판의 주행거리를 숫자로만 작성해주세요" />
                        </div>
                    </div>

                    <div className="flex">
                        <img src={ReturnCheck} alt="◽" className="ReturnCheckImage" />
                        <label htmlFor="" className="ReturnCheckLabel">차량점검내역</label>
                    </div>

                    <div>
                        <div className="ReturnDamageBB flex">
                            <label htmlFor="damage" className="ReturnDamageLabel">차량 외관 파손여부, 휠 / 타이어 / 차량본체</label>
                        </div>
                        <input type="damage" name="damage" id="damage" placeholder="특이사항이 없을 경우 없음으로 입력하세요" required={true} className="ReturnDamageInput" value={fieldValues.damage} onChange={handleFieldChange} maxLength='100' pattern="^[가-힣\s]+$" title="오타를 확인해주세요" />
                    </div>

                    <div>
                        <div className="ReturnLightBB flex">
                            <label htmlFor="lighting" className="ReturnLightLabel">조명장치 - 전조 / 후미 / 비상등</label>
                        </div>
                        <input type="lighting" name="lighting" id="lighting" placeholder="특이사항이 없을 경우 없음으로 입력하세요" required={true} className="ReturnLightInput" value={fieldValues.lighting} onChange={handleFieldChange} maxLength='100' pattern="^[가-힣\s]+$" title="오타를 확인해주세요" />
                    </div>

                    <div>
                        <div className="ReturnDashBB flex">
                            <label htmlFor="dashboard" className="ReturnDashLabel">계기판 - 경고등 점등 여부</label>
                        </div>
                        <input type="dashboard" name="dashboard" id="dashboard" placeholder="특이사항이 없을 경우 없음으로 입력하세요" required={true} className="ReturnDashInput" value={fieldValues.dashboard} onChange={handleFieldChange} maxLength='100' pattern="^[가-힣\s]+$" title="오타를 확인해주세요" />
                    </div>

                    <div>
                        <div className="ReturnBesideBB flex">
                            <label htmlFor="besides" className="ReturnBesideLabel">기타 - 조향 / 제동 계통</label>
                        </div>
                        <input type="besides" name="besides" id="besides" placeholder="특이사항이 없을 경우 없음으로 입력하세요" required={true} className="ReturnBesideInput" value={fieldValues.besides} onChange={handleFieldChange} maxLength='100' pattern="^[가-힣\s]+$" title="오타를 확인해주세요" />
                    </div>

                    <div className="flex">
                        <img src={ReturnRefuel} alt="◽" className="ReturnRefuelImage" />
                        <label htmlFor="refueling_amount" className="ReturnRefuelLabel">주유금액</label>
                        <input type="refueling_amount" name="refueling_amount" id="refueling_amount" placeholder="없을 경우 0으로 입력" required={true} className="ReturnRefuelInput" value={fieldValues.refueling_amount} onChange={handleFieldChange} maxLength='6' pattern="^[0-9]+$" title="주유금액을 숫자로만 작성해주세요" />
                    </div>

                    <div className="flex">
                        <img src={ReturnParking} alt="◽" className="ReturnParkingImage" />
                        <label htmlFor="parking" className="ReturnParkingLabel">주차위치</label>
                        <input type="parking" name="parking" id="parking" placeholder="지하, 도로변 정확하게 입력" required={true} className="ReturnParkingInput" value={fieldValues.parking} onChange={handleFieldChange} maxLength='100' pattern="^[가-힣\s|0-9]+$" title="주차장소를 한글로 정확히 적어주세요" />
                    </div>

                    <div className="ReturnButtonSet flex justify-end">
                        <button className="ReturnCancelButton" type="button" onClick={() => [emptyFieldValues(), setAdminResReturnOpen(false), setResDetailsOpen(true)]}>
                            <span className="ReturnCancelSpan">취소</span>
                        </button>
                        <button className="ReturnButton" type="submit">
                            <span className="ReturnSpan">반납</span>
                        </button>
                    </div>

                    <img src={ReturnModalCar} alt="🚘" className="ReturnModalCarImage" />
                </div>
            </form>
        </DialogBox>
        </>
    )
}

export default AdminResReturn;