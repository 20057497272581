import axios from "axios";
import { useAuth } from "base/hook/Authcontext";
import useFieldValues from "base/hook/useFieldValues";
import Logo from 'static/image/Logo194.png';
import User from 'static/image/User201.png';
import Id from 'static/image/Id196.png';
import Pw from 'static/image/Pw198.png';
import 'css/Validator.css';
import 'css/Login.css';
import { useState } from "react";


const INITIAL_STATE = { id: '', password: '' };


function LoginForm() {
    const { 
        handleFieldChange, 
        fieldValues, 
        handleSubmit, } = useFieldValues(INITIAL_STATE);
    
        const [, , login] = useAuth();
    
    const handleLoginButton = (e) => {
        e.preventDefault();
        handleSubmit(e, {...fieldValues})

        axios({
            method: 'post',
            url: `/api/login?id=${fieldValues.id}&password=${fieldValues.password}`,
            data: {...fieldValues, id: parseInt(fieldValues.id)},
        })
            .then((response) => {
                login(response);
        })
            .catch((error) => {
                console.log(error);
                window.alert('아이디나 비밀번호를 확인해주세요');
        });
    };
    
    return (
        <section className="select-none">
            <img src={Logo} alt="Carbble" className="LoginFormLogo" />

            <div className="LoginFormUsersSet">
                <img src={User} alt="😶" className="LoginFormUsersImage1" />
                <img src={User} alt="😶" className="LoginFormUsersImage2" />
                <img src={User} alt="😶" className="LoginFormUsersImage3" />
                <p className="LoginFormUsersP">Member login</p>
            </div>

            <form onSubmit={handleLoginButton}>
                <input type="hidden" name="remember" value="true"/>
                <div>
                    <div className="LoginFormIdSet">
                        <img src={Id} alt="ID" className="LoginFormIdImage" />
                        <input
                            id="id"
                            name="id"
                            type="id"
                            value={fieldValues.id}
                            onChange={handleFieldChange}
                            required="required"
                            className="LoginFormIdInput"
                            placeholder="아이디"
                            maxLength='9'
                            pattern="^[0-9]+$"
                            title="사원번호를 숫자로 입력해주세요"
                        />
                    </div>
                </div>
                            
                <div>
                    <div className="LoginFormPwSet">
                        <img src={Pw} alt="PW" className="LoginFormPwImage" />
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="off"
                            value={fieldValues.password}
                            onChange={handleFieldChange}
                            required="required"
                            className="LoginFormPwInput"
                            placeholder="비밀번호"/>
                    </div>
                </div>

                <div>
                    <button
                        type="submit"
                        className="LoginFormButton"
                    >
                            로그인
                    </button>
                </div>
            </form>
        </section>
    )
}

export default LoginForm;