import PopUp from "components/main/reservation/PopUp";
import Schedule from "components/main/reservation/Schedule";
import ErrorForbidden from "components/parts/ErrorForbidden";
import NotFound from "components/parts/NotFound";
import MainPage from "pages/MainPage";
import { Navigate, Route, Routes } from "react-router-dom";

function UserRouter() {
    return (
        <div className="z-0">
            <Routes>
                <Route path="/" element={<MainPage />} />
                
                <Route path="/403/" element={<ErrorForbidden />}/>
                <Route path="*" element={<NotFound />}/>
            </Routes>
        </div>
    );
}

export default UserRouter;