import 'css/ResData.css';


function ResData({ carId }) {

    return (
        <div className="relative">
            <div className='flex justify-between'>
                { carId === 2 ?
                    (
                        <div className="ResMorningSet flex"> 
                            <p className='ResMorningBall'/>
                            <span className='ResMorningData'>MORNING</span>
                        </div>
                    ) : (
                        <div className="ResTucsonSet"> 
                            <p className="ResTucsonBall" />
                            <span className='ResTucsonData'>TUCSON</span>
                        </div>
                    )
                }
            </div>
        </div>
    );
}
export default ResData;